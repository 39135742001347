:root{
  --font-family: Roboto;
  --logo-image: url(img/logo.png) no-repeat;
  --logo-background-size: auto 60px;
  --logo-width: 100px;
  --logo-height: 60px;
  --logo-margin: 10px 0;
  --cc-btn-primary-bg: #D2DC47;
  --cc-btn-primary-hover-bg: #1d2e38;
  --white-color: #FFFFFF;
  --black-color: #000000;
  --red-color: #dc3545;
  --orange-color: #FFA500;
  --first-grey-color: #EEEEEE;
  --second-grey-color: #F4F4F4;
  --third-grey-color: #555555;
  --fourth-grey-color: #999999;
  --fifth-grey-color: #333333;
  --sixth-grey-color: #AAAAAA;
  --seventh-grey-color: #DDDDDD;
  --eight-grey-color: #212529;
  --primary-color: #D2DC47;
  --primary-color-rbg: rgba(210, 220, 71, 1);
  --primary-color-rbg-hover: rgba(210, 220, 71, 0.8);
}

* { padding: 0; margin: 0; }
body { font-family: var(--font-family); font-size: 0.875em; color: var(--eight-grey-color); background: var(--white-color); }
body.loading { overflow-y: hidden; }

a { text-decoration: none; }
.container { max-width: 1500px; }

:focus { box-shadow: none; outline: none; }

button.top-btn { all: unset; position: fixed; width: 20px; height: 20px; background: var(--primary-color); right: 30px; bottom: 30px; cursor: pointer; transform:scale(1.8); opacity: 0; transition: .3s; text-align: center; color: var(--white-color); z-index: 9999; }

/***********************
TOPBAR
***********************/
#topbar { background: var(--first-grey-color); width: 100%; height: 32px; max-height: 32px; overflow-y: hidden; }
#topbar p { font-size: 0.875em; }
#topbar p, #topbar a { line-height: 32px; margin: 0; color: var(--fourth-grey-color); }
#topbar p strong { margin-right: 3px; }
#topbar a.jetzt-reg { font-weight: bold; border-bottom: 1px solid var(--primary-color); }
#topbar a:hover { color: var(--third-grey-color); }
#topbar a.lang { margin-left: 10px; }

/***********************
HEADER
***********************/
#header { background: var(--white-color); }
#header .logo a { background: var(--logo-image); background-size: var(--logo-background-size); width: var(--logo-width); height: var(--logo-height); display: block; margin: var(--logo-margin); }
#header .user-icon { display: inline-block; margin: 20px 0 0 0; position: relative; }
#header .user-icon a { height: 40px; font-size: 0.85em; color: var(--third-grey-color); display: block; font-weight: 600; text-align: center; transition: all ease 0.3s; }
#header .user-icon a:hover { color: var(--primary-color); transition: all ease 0.3s; }
#header .user-icon a p { margin: 0; display: inline-block; }
#header .user-icon a i { font-size: 24px; margin: 0 0 5px 0; display: block; }
#header .user-icon ul { min-width: 220px; border-radius: 0; padding: 0; border: 0; -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); transform: translate(0px, 45px) !important; }
#header .user-icon ul li.head { background: var(--primary-color); text-align: center; font-size: 0.9em; font-weight: bold; color: var(--white-color); padding: 8px 5px; margin-bottom: 5px; }
#header .user-icon ul li a { font-size: 0.9em; font-weight: 400; background: transparent; height: auto; padding: 1px 0; }
#header .user-icon ul li a:hover { background: transparent; height: auto; padding: 1px 0; }
#header .user-icon ul li.logout a { width: calc(100% - 20px); margin: 10px 10px 10px 10px; padding: 3px 0; background: var(--primary-color-rbg); display: block; color: var(--fifth-grey-color); font-weight: bold; }
#header .user-icon ul li.logout a:hover { background: var(--primary-color-rbg-hover); }
#header .cart-icon { display: inline-block; margin: 20px 35px 0 0; }
#header .cart-icon a { height: 40px; font-size: 0.85em; color: var(--third-grey-color); display: block; font-weight: 600; text-align: center; position: relative; transition: all ease 0.3s; }
#header .cart-icon a:hover { color: var(--primary-color); transition: all ease 0.3s; }
#header .cart-icon a p { margin: 0; display: inline-block; }
#header .cart-icon a i { font-size: 24px; margin: 0 0 5px 0; display: block; }
#header .cart-icon a span#count-wk { position: absolute; color: var(--third-grey-color); font-weight: 400; background: var(--primary-color); top: -5px; right: 10px; width: 18px; height: 18px; line-height: 18px; border-radius: 50%; }
#header .cart-icon a:hover span#count-wk { color: var(--black-color); }
#header .responsive-icon { display: none; }
#header .responsive-icon button { font-size: 24px; color: var(--third-grey-color); margin: 0; display: inline-block; vertical-align: inherit; padding: 0 !important; transition: all ease 0.3s; }
#header .responsive-icon button:hover { color: var(--primary-color); transition: all ease 0.3s; }
#header .responsive-icon button:focus { box-shadow: none; }
#header .responsive-icon button::after { font-family: 'Font Awesome 5 Pro'; content: "\f0c9"; font-weight: 900; }
#mainnav {	background: var(--first-grey-color); height: 70px; }
#mainnav ul:nth-child(1) { padding: 0; margin: 0; list-style: none; display: flex; flex-flow: row; flex-wrap: nowrap; width: 80%; float: left; }
#mainnav ul li { list-style: none; position: relative; }
#mainnav ul li > a { background: var(--first-grey-color); padding: 5px 8px; min-width: 90px; height: 70px; display: block; border-right: 1px solid var(--white-color); color: var(--third-grey-color); text-decoration: none; position: relative; }
#mainnav ul li > a:hover, #mainnav ul li:hover > a { background: var(--primary-color); }
#mainnav ul li .subnav::before { width: 16px; height: 16px; background: var(--primary-color); content: ""; position: absolute; top: -8px; left: 37px; transform: rotateY(0deg) rotate(45deg); z-index: 9999; }
#mainnav ul li .subnav { background: var(--white-color); opacity: 0; display: none; position: absolute; width: 100%; min-width: calc(800px - 24px); max-width: calc(800px - 24px); transform: translate(0, 70px); margin: 0; padding: 15px 0px; top: 0; z-index: 999; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); }
#mainnav ul li:hover .subnav { opacity: 1; display: block; }
#mainnav ul li .subnav > ul { width: 100%; display: flex; flex-wrap: wrap; flex-direction: row; align-items: flex-start; }
#mainnav ul li ul li { width: 33.3%; display: inline-block; border-right: 1px solid var(--seventh-grey-color); }
#mainnav ul li ul li:nth-child(3n) { border-right: none; }
#mainnav ul li ul li a { background: url(img/icons/001-right-arrow-angle.png) no-repeat transparent; background-size: 12px 12px; background-position: 15px 5px; height: auto; min-width: 0px; padding: 0 15px 5px 35px; border: 0; }
#mainnav ul li ul li a:hover { background: url(img/icons/001-right-arrow-angle.png) no-repeat transparent; background-size: 12px 12px; background-position: 15px 5px; text-decoration: underline; }
#mainnav ul li ul li a:hover::after { display: none; }
#mainnav ul li ul li ul { display: none; }
#mainnav ul:nth-child(2) { width: 20%; float: right; padding: 0; margin: 0; text-align: right; }
#mainnav ul:nth-child(2) li { display: inline-block; }
#mainnav ul:nth-child(2) li a { background: var(--red-color); color: var(--white-color); font-weight: bold; text-align: left; }

/***********************
SEARCHBAR
***********************/
#searchbar { background: var(--primary-color); }
#searchbar form { margin: 10px 0; }
#searchbar form input[type="text"] { width: 100%; padding: 10px 8px; border: 0; }
#searchbar form input[type="submit"] { display: none; }
#content { padding: 30px 0; }

/***********************
BREADCRUMB
***********************/
#breadcrumb { border-bottom: 1px solid var(--first-grey-color); }
#breadcrumb ul { list-style: none; padding: 0; margin: 0; }
#breadcrumb ul li { float: left; }
#breadcrumb ul li a { padding: 12px 0; display: inline-block; color: var(--sixth-grey-color); font-size: 0.85em; }
#breadcrumb ul li.start a i { margin-right: 10px; color: var(--fifth-grey-color); }
#breadcrumb ul li::after { content: ">"; display: inline-block;	padding: 0 5px; font-size: 0.85em; }
#breadcrumb ul li:last-child::after { display: none; }

/***********************
STARTSEITE
***********************/
#content .startseite .col-lg-2 .show_box { background: var(--second-grey-color); padding: 1px; }
#content .startseite .col-lg-2 .show_box:nth-child(1) { margin-bottom: 17px; }
#content .startseite .col-lg-2 .show_box img { width: 100%; height: auto; }
#content .startseite .col-lg-2 .show_box:hover { background: var(--primary-color); }
/****CONTENT SLIDER***********************/
#content .startseite .col-lg-10 .complete_slider { background: var(--second-grey-color);	height: 100%; display: block; min-height: 448px; max-height: 448px;	overflow-y: hidden;	padding: 1px; }
#content .startseite .col-lg-10 .complete_slider #acc-container { display: block; }
#content .startseite .col-lg-10 .complete_slider #accordion__img { width: 65%; float: left; margin: 0; overflow: hidden; }
#content .startseite .col-lg-10 .complete_slider #accordion__img img { min-height: 446px; max-height: 446px; height: 100%; display: block; }
#content .startseite .col-lg-10 .complete_slider #services__accordion { width: 35%; float: left; margin: 0; }
#content .startseite .col-lg-10 .complete_slider #services__accordion h3 { position: relative; display: flex; align-items: center; cursor: pointer; width: 100%; padding: 1rem 1.25rem; font-size: 1rem; color: var(--eight-grey-color); font-weight: bold; text-align: left; background-color: transparent; border: 0; border-radius: 0; margin: 0;    overflow-anchor: none; transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease; }
#content .startseite .col-lg-10 .complete_slider #services__accordion h3.active { color: var(--primary-color); }
#content .startseite .col-lg-10 .complete_slider #services__accordion .accordion__body { padding: 0 1.25rem 1rem 1.25rem; }
#content .startseite .col-lg-10 .complete_slider #services__accordion .accordion__body p { margin: 0; }
#content .startseite .col-lg-10 .complete_slider #services__accordion .accordion__body a { background: var(--first-grey-color); border: 1px solid var(--seventh-grey-color); color: var(--eight-grey-color); padding: 7px 12px;
margin: 10px 0 0 0; font-weight: bold; text-transform: uppercase; display: inline-block; text-decoration: none; -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); position: relative; transition: all ease 0.2s; }
#content .startseite .col-lg-10 .complete_slider #services__accordion .accordion__body a::before { background: url(img/icons/forward.png) no-repeat transparent; background-size: 10px 10px; background-position: center 6px; content: ""; width: 16px; height: 16px; margin-right: 5px; display: inline-block; }
#content .startseite .col-lg-10 .complete_slider #services__accordion .accordion__body a:hover { background: var(--primary-color); -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15); box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15); color: var(--white-color); transition: all ease 0.2s; }
#content .startseite .col-lg-10 .complete_slider #services__accordion .accord { border-bottom: 1px solid var(--seventh-grey-color); }
/****TOPSELLER: OHNE SLIDER***********************/
#content .startseite .top_products { margin-top: 30px; }
#content .startseite .top_products .head { background: var(--second-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 30px; }
#content .startseite .top_products .head p { margin: 0; }
#content .startseite .top_products .products { display: flex; flex-wrap: nowrap; }
#content .startseite .top_products .products .item { width: 16.66%; margin-right: 20px; border: 1px solid var(--second-grey-color); transition: all 0.4s ease; }
#content .startseite .top_products .products .item:hover { -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05); -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05); box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05); transition: all 0.4s ease; }
#content .startseite .top_products .products .item:last-child { margin-right: 0px; }
#content .startseite .top_products .products .item .img { text-align: center; padding: 10px; min-height: 225px; line-height: 205px; }
#content .startseite .top_products .products .item .img a { display: block; text-align: center; }
#content .startseite .top_products .products .item .img img { width: auto; max-width: 100%; height: auto; max-height: 205px; display: inline-block; }
#content .startseite .top_products .products .item .title { font-weight: bold; padding: 10px 10px 5px 10px; margin: 0; min-height: 78px; max-height: 78px; }
#content .startseite .top_products .products .item .artnr { color: var(--sixth-grey-color); padding: 0px 10px; margin: 0; }
#content .startseite .top_products .products .item .price { font-size: 18px; font-weight: bold; padding: 10px 10px 0 10px; margin: 0; }
#content .startseite .top_products .products .item .price span { font-size: 14px; font-weight: 400; }
#content .startseite .top_products .products .item .brutto_price { font-size: 12px; color: var(--sixth-grey-color); padding: 0px 10px 0px 10px; margin: 0; }
#content .startseite .top_products .products .item .brutto_price.padbottom { padding: 0px 10px 10px 10px; }
#content .startseite .top_products .products .item .status { font-size: 12px; font-weight: bold; padding: 10px 10px 0 10px; }
#content .startseite .top_products .products .item .status.green { color: var(--primary-color); }
#content .startseite .top_products .products .item .status.red { color: var(--red-color); }
#content .startseite .top_products .products .item .status.orange { color: var(--orange-color); }
#content .startseite .top_products .products .item .addtocart { padding: 0px 10px 10px 10px; }
#content .startseite .top_products .products .item .addtocart .qtnty-minus,
#content .startseite .top_products .products .item .addtocart .qtnty-plus { width: 38px; height: 38px; line-height: 38px; border: 1px solid var(--seventh-grey-color);
display: block;	text-align: center;	float: left; cursor: pointer; }
#content .startseite .top_products .products .item .addtocart .qtnty-minus:hover,
#content .startseite .top_products .products .item .addtocart .qtnty-plus:hover { background: var(--white-color); }
#content .startseite .top_products .products .item .addtocart input#menge { width: calc(100% - 76px); padding: 8px 5px 7px 5px; border-top: 1px solid var(--seventh-grey-color);	border-bottom: 1px solid var(--seventh-grey-color); border-left: none; border-right: none; text-align: center; display: block; float: left; margin: 0; }
/****TOPSELLER: MIT SLIDER***********************/
#content .startseite .top_products .products .owl-carousel .item { width: calc(100% - 2px); padding-bottom: 10px; }
#content .startseite .top_products .products .owl-carousel .item .addtocart .add-to-cart { margin-bottom: 0; }
#content .startseite .top_products .products .owl-carousel .owl-nav { width: 100%; margin: 0; position: absolute; top: 50%; transform: translateY(-50%); text-align: left; }
#content .startseite .top_products .products .owl-carousel .owl-nav .owl-prev { width: 36px; height: 36px; background: var(--primary-color); left: -50px; position: absolute; margin: 0; border-radius: 0; color: var(--white-color); }
#content .startseite .top_products .products .owl-carousel .owl-nav .owl-prev span { display: none; }
#content .startseite .top_products .products .owl-carousel .owl-nav .owl-prev::before { content: "\f053"; font-family: "Font Awesome 5 Pro"; font-size: 20px; color: var(--white-color); font-weight: 900; opacity: .75; }
#content .startseite .top_products .products .owl-carousel .owl-nav .owl-prev:hover::before { opacity: 1; }
#content .startseite .top_products .products .owl-carousel .owl-nav .owl-next { width: 36px; height: 36px; background: var(--primary-color); right: -50px; position: absolute; margin: 0; border-radius: 0; color: var(--white-color); }
#content .startseite .top_products .products .owl-carousel .owl-nav .owl-next span { display: none; }
#content .startseite .top_products .products .owl-carousel .owl-nav .owl-next::before { content: "\f054"; font-family: "Font Awesome 5 Pro"; font-size: 20px; color: var(--white-color); font-weight: 900; opacity: .75; }
#content .startseite .top_products .products .owl-carousel .owl-nav .owl-next:hover::before { opacity: 1; }
/****BRANDS: SLIDER***********************/
#content .brands { background: var(--second-grey-color); padding: 30px 0; margin: 30px 0; }
#content .brands .slick-prev { width: 36px; height: 36px; background: var(--primary-color); left: -50px; }
#content .brands .slick-prev::before { content: "\f053"; font-family: "Font Awesome 5 Pro"; font-weight: 900; }
#content .brands .slick-next { width: 36px; height: 36px; background: var(--primary-color); right: -50px; }
#content .brands .slick-next::before { content: "\f054"; font-family: "Font Awesome 5 Pro"; font-weight: 900; }
/****SEO-TEXT***********************/
#content .text-seo h1 { font-size: 16px; color: #585858; }
#content .text-seo span { font-family: var(--font-family) !important; font-size: 1em !important; }
#content .text-seo .container { height: 400px; overflow: hidden; position: relative; }
#content .text-seo .container::after { position: absolute; bottom: 0; content: ""; background: rgb(0,0,0); background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,.75) 35%, rgba(255,255,255,0) 100%); width: 100%; height: 400px; z-index: 2; }
#content .text-seo .container.nogradient::after { display: none; }
#content .text-seo .container button#readmore { position: absolute; left: 50%; transform: translate(-50%, 0); top: 370px; z-index: 3; border: none; background: transparent; font-weight: bold; text-transform: uppercase; }
#content .text-seo .container button#readmore:hover { text-decoration: underline; }

/***********************
PRODUKTLISTE
***********************/
#content .produkte-kategorie .head.category { padding: 10px; background: var(--primary-color); color: var(--white-color); font-weight: bold; }
#content .produkte-kategorie ul.sidebar-nav { list-style: none; padding: 0; margin: 10px 0 0 0; }
#content .produkte-kategorie ul.sidebar-nav li { border-bottom: 1px solid var(--first-grey-color); }
#content .produkte-kategorie ul.sidebar-nav li.vorheriges-element a { font-size: 13px; line-height: 42px; color: var(--sixth-grey-color); display: block; }
#content .produkte-kategorie ul.sidebar-nav li.vorheriges-element a i { color: var(--fifth-grey-color); margin-right: 5px; }
#content .produkte-kategorie ul.sidebar-nav li.vorheriges-element a:hover { text-decoration: underline; }
#content .produkte-kategorie ul.sidebar-nav li.aktuelles-element a { font-size: 16px; font-weight: 600; line-height: 42px; color: var(--fifth-grey-color); display: block; }
#content .produkte-kategorie ul.sidebar-nav li.aktuelles-element a:hover { text-decoration: underline; }
#content .produkte-kategorie ul.sidebar-nav li.next a { line-height: 42px; color: var(--fifth-grey-color); display: block; }
#content .produkte-kategorie ul.sidebar-nav li.next a:hover { text-decoration: underline; }
#content .produkte-kategorie ul.sidebar-nav li.next a img { width: 30px; height: 30px; margin-right: 10px; }
#content .produkte-kategorie ul.sidebar-nav li.next a i { float: right; line-height: 42px; }
#content .produkte-kategorie h1 { font-size: 2em; color: var(--fifth-grey-color); margin-bottom: 30px; }
#content .produkte-kategorie h1 span { font-size: 0.7142857142857143em; color: var(--sixth-grey-color); margin-left: 20px; }
#content .produkte-kategorie .description { margin-bottom: 20px; }
#content .produkte-kategorie .cat-headline { background: var(--first-grey-color); padding: 10px 15px; margin-bottom: 20px; display: block; }
#content .produkte-kategorie .product_filter { border-top: 1px solid var(--first-grey-color); padding-top: 20px; margin-bottom: 30px; display: flex; flex-flow: row; flex-wrap: nowrap; }
#content .produkte-kategorie .product_filter.down { margin-bottom: 0; }
#content .produkte-kategorie .product_filter .left_filter { width: 35%; display: flex; }
#content .produkte-kategorie .product_filter .left_filter .sort_filter .select-wrapper { width: 170px; position: relative; background: var(--first-grey-color); border-radius: 4px; margin-right: 10px; }
#content .produkte-kategorie .product_filter .left_filter .sort_filter .select-wrapper::before { font-family: "Font Awesome 5 Pro"; font-weight: 900; content: "\f078"; position: absolute; right: 10px; top: 5px; color: var(--fifth-grey-color); }
#content .produkte-kategorie .product_filter .left_filter .sort_filter select { width: 170px; background: transparent; -moz-appearance: none; -webkit-appearance: none; appearance: none; border: none; padding: 4px 10px; }
#content .produkte-kategorie .product_filter .left_filter .count_filter .select-wrapper { width: 170px; position: relative; background: var(--first-grey-color); border-radius: 4px; }
#content .produkte-kategorie .product_filter .left_filter .count_filter .select-wrapper::before { font-family: "Font Awesome 5 Pro"; font-weight: 900; content: "\f078"; position: absolute; right: 10px; top: 5px; color: var(--fifth-grey-color); }
#content .produkte-kategorie .product_filter .left_filter .count_filter select { width: 170px; background: transparent; -moz-appearance: none; -webkit-appearance: none; appearance: none; border: none; padding: 4px 10px; }
#content .produkte-kategorie .product_filter .right_filter { width: 65%; text-align: right; }
#content .produkte-kategorie .product_filter .right_filter .page_info { display: inline-block; }
#content .produkte-kategorie .product_filter .right_filter .page_info ul { margin: 0; padding: 0; list-style: none; }
#content .produkte-kategorie .product_filter .right_filter .page_info ul li { display: inline-block; }
#content .produkte-kategorie .product_filter .right_filter .page_info ul li a { width: 26px; font-size: 14px; font-weight: 400; color: var(--fifth-grey-color); display: block; border-bottom: 2px solid var(--white-color); text-align: center; }
#content .produkte-kategorie .product_filter .right_filter .page_info ul li:hover a { font-size: 18px; font-weight: bold; color: var(--primary-color); display: block; border-bottom: 2px solid var(--white-color); }
#content .produkte-kategorie .product_filter .right_filter .page_info ul li.active a { width: 26px; font-size: 18px; font-weight: bold; color: var(--primary-color); display: block; border-bottom: 2px solid var(--primary-color); text-align: center; }
#content .produkte-kategorie .product_filter .right_filter .ansicht { display: inline-block; margin-left: 60px; }
#content .produkte-kategorie .product_filter .right_filter .ansicht p { display: inline-block; margin-bottom: 0; margin-right: 5px; font-weight: bold; }
#content .produkte-kategorie .product_filter .right_filter .ansicht ul { display: inline-block; margin: 0; padding: 0; list-style: none; }
#content .produkte-kategorie .product_filter .right_filter .ansicht ul li { display: inline-block; }
#content .produkte-kategorie .product_filter .right_filter .ansicht ul li span { width: 26px; cursor: pointer; display: block; border-bottom: 2px solid var(--white-color); font-size: 18px; text-align: center; }
#content .produkte-kategorie .product_filter .right_filter .ansicht ul li:hover span { width: 26px; color: var(--primary-color); cursor: pointer; display: block; font-size: 18px; border-bottom: 2px solid var(--white-color); }
#content .produkte-kategorie .product_filter .right_filter .ansicht ul li.active span { width: 26px; color: var(--primary-color); cursor: pointer; display: block; font-size: 18px; border-bottom: 2px solid var(--primary-color); }
#content .produkte-kategorie .product_list .grid-view, #content .produkte-kategorie .product_list .list-view,
#content .produkte-kategorie .product_list .table-view { display: none; }
#content .produkte-kategorie .product_list .grid-view.show, #content .produkte-kategorie .product_list .list-view.show,
#content .produkte-kategorie .product_list .table-view.show { display: block; }
/*PRODUKT GRID*/
#content .produkte-kategorie .product_list .product_list_grid { display: flex; flex-wrap: wrap; }
#content .produkte-kategorie .product_list .product_list_grid .product { width: calc(25% - 16px); margin-right: 20px; margin-bottom: 20px; border-top: 1px solid var(--first-grey-color); border-left: 1px solid var(--first-grey-color); border-right: 1px solid var(--first-grey-color); border-bottom: 1px solid var(--first-grey-color); overflow: hidden; transition: all 0.3s ease; }
#content .produkte-kategorie .product_list .product_list_grid .product:hover { border: 1px solid var(--first-grey-color); -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05); -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05); box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05); transition: all 0.4s ease; }
#content .produkte-kategorie .product_list .product_list_grid .product:nth-child(4n) { margin-right: 0; }
#content .produkte-kategorie .product_list .product_list_grid .product a { width: 100%; height: auto; display: block; padding: 0; }
#content .produkte-kategorie .product_list .product_list_grid .product .img { text-align: center; width: 100%; min-height: 288px; line-height: 268px; padding: 10px; }
#content .produkte-kategorie .product_list .product_list_grid .product img { width: auto; height: 100%; max-width: 100%; max-height: 268px; }
#content .produkte-kategorie .product_list .product_list_grid .product .title { font-size: 1.071428571428571em; line-height: 1.4em; min-height: 52px; padding: 10px 10px 0 10px; margin: 0; font-weight: 600; color: var(--fifth-grey-color); display: block; }
#content .produkte-kategorie .product_list .product_list_grid .product .description { padding: 10px 10px 0px 10px; margin: 0; color: var(--sixth-grey-color); }
#content .produkte-kategorie .product_list .product_list_grid .product .price { font-size: 18px; font-weight: bold; color: var(--fifth-grey-color); padding: 10px 10px 0px 10px; margin: 0; }
#content .produkte-kategorie .product_list .product_list_grid .product .price span { font-size: 14px; color: var(--fifth-grey-color); font-weight: 400; }
#content .produkte-kategorie .product_list .product_list_grid .product .brutto_price { font-size: 12px; color: var(--sixth-grey-color); padding: 0px 10px 0px 10px; margin: 0; }
#content .produkte-kategorie .product_list .product_list_grid .product .brutto_price.padbottom { padding: 0px 10px 10px 10px; }
#content .produkte-kategorie .product_list .product_list_grid .product .status { padding: 10px 10px 10px 10px; line-height: 27px; margin: 0; font-weight: bold; text-align: left; }
#content .produkte-kategorie .product_list .product_list_grid .product .status.green { color: var(--primary-color); }
#content .produkte-kategorie .product_list .product_list_grid .product .status.red { color: var(--red-color); }
#content .produkte-kategorie .product_list .product_list_grid .product .status.orange { color: var(--orange-color); }
#content .produkte-kategorie .product_list .product_list_grid .product .addtocart { padding: 10px; }
#content .produkte-kategorie .product_list .product_list_grid .product .addtocart .qtnty-minus,
#content .produkte-kategorie .product_list .product_list_grid .product .addtocart .qtnty-plus { width: 38px; height: 38px; line-height: 38px; border: 1px solid var(--seventh-grey-color);
display: block;	text-align: center;	float: left; cursor: pointer; }
#content .produkte-kategorie .product_list .product_list_grid .product .addtocart .qtnty-minus:hover,
#content .produkte-kategorie .product_list .product_list_grid .product .addtocart .qtnty-plus:hover { background: var(--white-color); }
#content .produkte-kategorie .product_list .product_list_grid .product .addtocart input#menge { width: calc(100% - 76px); padding: 8px 5px 7px 5px; border-top: 1px solid var(--seventh-grey-color);	border-bottom: 1px solid var(--seventh-grey-color); border-left: none; border-right: none; text-align: center; display: block; float: left; margin: 0; }
#content .produkte-kategorie .product_list .product_list_grid .product .addtocart .submit-container a#addcart_grid { width: 100%; background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); display: block; margin: 20px 0px 10px 0px; height: 50px; line-height: 50px; border: 0; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; }
#content .produkte-kategorie .product_list .product_list_grid .product .addtocart .submit-container a#addcart_grid:hover { background: var(--primary-color-rbg-hover); }
#content .produkte-kategorie .product_list .product_list_grid .product .addtocart .submit-container a#addcart_grid i { margin-left: 10px; }
/*PRODUKT LIST*/
#content .produkte-kategorie .product_list .product_list_list { display: block; }
#content .produkte-kategorie .product_list .product_list_list .product { border-bottom: 1px solid var(--first-grey-color); display: flex; flex-wrap: nowrap; padding: 10px 0px 20px 0px; margin-bottom: 10px; }
#content .produkte-kategorie .product_list .product_list_list .product:last-child { border-bottom: none; margin-bottom: 20px; }
#content .produkte-kategorie .product_list .product_list_list .product .img { width: calc(25% - 14px); margin-right: 21px; text-align: center; }
#content .produkte-kategorie .product_list .product_list_list .product .img img { width: auto; height: 100%; max-width: 100%; max-height: 268px; }
#content .produkte-kategorie .product_list .product_list_list .product .title_info { width: calc(50% - 14px); margin-right: 21px; }
#content .produkte-kategorie .product_list .product_list_list .product .title_info .title { font-size: 1.071428571428571em; padding: 10px 0px 0px 0px; margin: 0; font-weight: 600; color: var(--fifth-grey-color); display: block; }
#content .produkte-kategorie .product_list .product_list_list .product .title_info .description { padding: 10px 0px 0px 0px; margin: 0; color: var(--sixth-grey-color); }
#content .produkte-kategorie .product_list .product_list_list .product .title_info .status { padding: 10px 0px 0px 0px; margin: 0; font-weight: bold; text-align: left; }
#content .produkte-kategorie .product_list .product_list_list .product .title_info .status.green { color: var(--primary-color); }
#content .produkte-kategorie .product_list .product_list_list .product .title_info .status.red { color: var(--red-color); }
#content .produkte-kategorie .product_list .product_list_list .product .title_info .status.orange { color: var(--orange-color); }
#content .produkte-kategorie .product_list .product_list_list .product .grey_box { width: 25%; background: var(--first-grey-color); padding: 20px; }
#content .produkte-kategorie .product_list .product_list_list .product .grey_box p { margin: 0; }
#content .produkte-kategorie .product_list .product_list_list .product .grey_box .product_price .price { font-size: 18px; font-weight: bold; }
#content .produkte-kategorie .product_list .product_list_list .product .grey_box .price_brutto { font-size: 0.875em; }
#content .produkte-kategorie .product_list .product_list_list .product .grey_box .quantity { margin-top: 20px; }
#content .produkte-kategorie .product_list .product_list_list .product .grey_box .quantity .qtnty-minus,
#content .produkte-kategorie .product_list .product_list_list .product .grey_box .quantity .qtnty-plus { width: 38px; height: 38px; line-height: 38px; border: 1px solid var(--seventh-grey-color);
display: block;	text-align: center;	float: left; cursor: pointer; }
#content .produkte-kategorie .product_list .product_list_list .product .grey_box .quantity .qtnty-minus:hover,
#content .produkte-kategorie .product_list .product_list_list .product .grey_box .quantity .qtnty-plus:hover { background: var(--white-color); }
#content .produkte-kategorie .product_list .product_list_list .product .grey_box .quantity input#menge { width: calc(100% - 76px); padding: 8px 5px 7px 5px; border-top: 1px solid var(--seventh-grey-color);	border-bottom: 1px solid var(--seventh-grey-color); border-left: none; border-right: none; text-align: center; display: block; float: left; margin: 0; }
#content .produkte-kategorie .product_list .product_list_list .product .grey_box .quantity .submit-container a#addcart_list { width: 100%; background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); display: block; margin-top: 20px; height: 50px; line-height: 50px; border: 0; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; }
#content .produkte-kategorie .product_list .product_list_list .product .grey_box .quantity .submit-container a#addcart_list:hover { background: var(--primary-color-rbg-hover); }
#content .produkte-kategorie .product_list .product_list_list .product .grey_box .quantity .submit-container a#addcart_list i { margin-left: 10px; }
/*PRODUKT TABELLE*/
#content .produkte-kategorie .product_list table.product-table { width: 100%; margin-bottom: 20px; }
#content .produkte-kategorie .product_list table.product-table thead tr th { background: var(--primary-color); padding: 10px; }
#content .produkte-kategorie .product_list table.product-table thead tr th.last { min-width: 185px; width: 185px; }
#content .produkte-kategorie .product_list table.product-table tbody tr:nth-child(even) td { background: var(--second-grey-color); }
#content .produkte-kategorie .product_list table.product-table tbody tr:nth-child(odd) td { background: var(--white-color);  }
#content .produkte-kategorie .product_list table.product-table tbody tr td p { margin: 0; }
#content .produkte-kategorie .product_list table.product-table tbody tr td a { width: 100%; padding: 10px; display: block; color: var(--fifth-grey-color); }
#content .produkte-kategorie .product_list table.product-table tbody tr td .qtnty-minus,
#content .produkte-kategorie .product_list table.product-table tbody tr td .qtnty-plus { background: var(--second-grey-color); width: 31px; height: 31px; line-height: 31px; text-align: center; float: left; margin: 5px 0; cursor: pointer; color: var(--fifth-grey-color); transition: all 0.2s ease; }
#content .produkte-kategorie .product_list table.product-table tbody tr td .qtnty-minus:hover,
#content .produkte-kategorie .product_list table.product-table tbody tr td .qtnty-plus:hover { color: var(--primary-color); transition: all 0.2s ease; }
#content .produkte-kategorie .product_list table.product-table tbody tr td .qtnty-minus { margin-left: 10px; }
#content .produkte-kategorie .product_list table.product-table tbody tr td .quantity { float: left; }
#content .produkte-kategorie .product_list table.product-table tbody tr td .quantity input { width: 60px; border: 0; margin: 5px 0; line-height: 31px; background: var(--second-grey-color); text-align: center; }
#content .produkte-kategorie .product_list table.product-table tbody tr td .submit-container button { width: 31px; height: 31px; background-color: var(--primary-color-rbg); margin: 5px 0px 5px 5px; box-shadow: none; border: 0; transition: all 0.2s ease; }
#content .produkte-kategorie .product_list table.product-table tbody tr td .submit-container button:hover { background-color: var(--primary-color-rbg-hover); transition: all 0.2s ease; }
#content .produkte-kategorie .product_list table.product-table tbody tr td .submit-container button .default { top: -8px; }
#content .produkte-kategorie .product_list table.product-table tbody tr td .submit-container button .default i { margin-left: 0; }
#content .produkte-kategorie .product_list table.product-table tbody tr td .submit-container button .success { top: 7px; }
#content .produkte-kategorie .product_list table.product-table tbody tr td .submit-container button .success i { margin-left: 0; }

/***********************
LOGINPAGE
***********************/
.loginpage .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 30px; }
.loginpage .head p { margin: 0; }
.loginpage ul.vorteile { list-style: none; background: none; padding: 0; }
.loginpage ul.vorteile li { border-bottom: 1px solid var(--seventh-grey-color); padding: 7px 0px; }
.loginpage ul.vorteile li:first-child { padding-top: 0px; }
.loginpage ul.vorteile li:last-child { border-bottom: none; }
.loginpage ul.vorteile li i { margin-right: 5px; }
.loginpage .login .loginform .username { margin: 0 0 10px 0; }
.loginpage .login .loginform .username, .loginpage .login .loginform .password { position: relative; z-index: 1; }
.loginpage .login .loginform input[type="text"],
.loginpage .login .loginform input[type="password"] { width: 100%; padding: 20px 12px 5px 12px; border: 1px solid var(--seventh-grey-color); position: relative; z-index: 2; }
.loginpage .login .loginform input.active,
.loginpage .login .loginform input:focus { border: 1px solid var(--seventh-grey-color); box-shadow: none; outline: none; }
.loginpage .login .loginform label { transition: all .3s ease; }
.loginpage .login .loginform label.active { transition: all .3s ease; }
.loginpage .login .loginform label { position: absolute; z-index: 3; padding: 0 10px; top: 14px; left: 2px; cursor: text;  }
.loginpage .login .loginform label.active { top: 5px; font-size: 11px; }
.loginpage .login .loginform input:valid + label { top: 5px; font-size: 11px; }
.loginpage .login .loginform .submit_container { text-align: left; }
.loginpage .login .loginform .submit_container input[type="submit"] { min-width: 200px; background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); display: inline-block; margin-top: 20px; height: 50px; line-height: 50px; border: none; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; }
.loginpage .login .loginform .submit_container input[type="submit"]:hover { background: var(--primary-color-rbg-hover); }
.loginpage .reg .txt p { margin: 0 0 20px 0; }
.loginpage .reg .txt p a { color: var(--third-grey-color); font-weight: bold; border-bottom: 1px solid var(--primary-color); }
.loginpage .reg .txt p a:hover { color: var(--eight-grey-color); }
.loginpage .reg .txt p:last-child { margin: 0; }

/***********************
REGISTRIERUNG
***********************/
.registrierung-formular h1 { font-size: 2em; color: var(--fifth-grey-color); margin-bottom: 30px; }
.registrierung-formular form .col-lg-12 .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 10px; }
.registrierung-formular form .col-lg-12 .head p { margin: 0; }
.registrierung-formular form .reg-adresse, 
.registrierung-formular form .reg-ansprechpartner { margin: 20px 0 0 0; }
.registrierung-formular form .col-lg-12,
.registrierung-formular form .col-lg-6 { margin-bottom: 10px; }
.registrierung-formular form .col-lg-6:nth-child(1) { padding-right: 5px; }
.registrierung-formular form .col-lg-6:nth-child(2) { padding-left: 5px; }
.registrierung-formular form .widget { position: relative; z-index: 1; }
.registrierung-formular form .widget.widget-radio label { padding-left: 12px; margin-bottom: 3px; display: block; }
.registrierung-formular form .widget.widget-radio label:last-child { margin: 0; }
.registrierung-formular form .widget.widget-text input { width: 100%; padding: 20px 12px 5px 12px; border: 1px solid var(--seventh-grey-color); position: relative; z-index: 2; }
.registrierung-formular form .widget.widget-text input.active,
.registrierung-formular form .widget.widget-text input:focus { border: 1px solid var(--seventh-grey-color); }
.registrierung-formular form .widget.widget-select select { width: 100%; background: var(--white-color); padding: 20px 12px 5px 12px; border: 1px solid var(--seventh-grey-color); position: relative; z-index: 2; }
.registrierung-formular form .widget.widget-select select:focus { border: 1px solid var(--seventh-grey-color); }
.registrierung-formular form .widget label { transition: all .3s ease; }
.registrierung-formular form .widget label.active { transition: all .3s ease; }
.registrierung-formular form .widget.widget-text label { position: absolute; z-index: 3; padding: 0 10px; top: 14px; left: 2px; cursor: text;  }
.registrierung-formular form .widget.widget-select label { position: absolute; font-size: 11px; z-index: 3; padding: 0 10px; top: 5px; left: 2px; cursor: text; }
.registrierung-formular form .widget.widget-text label.active { top: 5px; font-size: 11px; }
.registrierung-formular form .widget.widget-select label.active { top: 5px; font-size: 11px; }
.registrierung-formular form .widget.widget-text input:valid + label { top: 5px; font-size: 11px; }
.registrierung-formular form .widget.widget-checkbox { border: 1px solid var(--seventh-grey-color); padding: 12px; }
.registrierung-formular form .widget.widget-checkbox input { position: relative; width: 14px; margin-right: 10px; top: 3px; float: left; cursor: pointer; }
.registrierung-formular form .widget.widget-checkbox label { width: calc(100% - 24px); float: left; cursor: pointer; }
.registrierung-formular form .widget.widget-checkbox label a { color: var(--primary-color); font-weight: bold; }
.registrierung-formular form .widget.widget-submit { text-align: left; }
.registrierung-formular form .widget.widget-submit input[type="submit"] { min-width: 200px; background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); display: inline-block; margin-top: 20px; height: 50px; line-height: 50px; border: none; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; }
.registrierung-formular form .widget.widget-submit input[type="submit"]:hover { background: var(--primary-color-rbg-hover); }

/*Validation*/
.registrierung-formular form input:user-invalid,
.registrierung-formular form select:user-invalid,
.registrierung-formular form .widget-checkbox.invalid { border-color: var(--red-color) !important; }

/***********************
REGISTRIERUNG ABGESCHLOSSEN
***********************/
.reg-finish h1 { font-size: 2em; color: var(--fifth-grey-color); margin-bottom: 30px; }
.reg-finish p { margin: 0; }
.reg-finish p.marb20 { margin-bottom: 20px; }
.reg-finish p.marb30 { margin-bottom: 30px; }
.reg-finish p a { padding: 0px 50px; border: 0; background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); height: 50px; line-height: 50px; text-align: center; font-weight: 600; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); display: inline-block; }
.reg-finish p a:hover { background: var(--primary-color-rbg-hover); }

/***********************
PASSWORT ZURÜCKSETZEN
***********************/
.passwort-zuruecksetzen h1 { font-size: 2em; color: var(--fifth-grey-color); margin-bottom: 30px; }
.passwort-zuruecksetzen form .col-lg-12 .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 10px; }
.passwort-zuruecksetzen form .col-lg-12 .head p { margin: 0; }
.passwort-zuruecksetzen form .col-lg-12 { margin-bottom: 10px; }
.passwort-zuruecksetzen form .widget { position: relative; z-index: 1; }
.passwort-zuruecksetzen form .widget.widget-text input { width: 100%; padding: 20px 12px 5px 12px; border: 1px solid var(--seventh-grey-color); position: relative; z-index: 2; }
.passwort-zuruecksetzen form .widget.widget-text input.active,
.passwort-zuruecksetzen form .widget.widget-text input:focus { border: 1px solid var(--seventh-grey-color); }
.passwort-zuruecksetzen form .widget label { transition: all .3s ease; }
.passwort-zuruecksetzen form .widget label.active { transition: all .3s ease; }
.passwort-zuruecksetzen form .widget.widget-text label { position: absolute; z-index: 3; padding: 0 10px; top: 14px; left: 2px; cursor: text;  }
.passwort-zuruecksetzen form .widget.widget-text label.active { top: 5px; font-size: 11px; }
.passwort-zuruecksetzen form .widget.widget-text input:valid + label { top: 5px; font-size: 11px; }
.passwort-zuruecksetzen form .widget.widget-submit { text-align: left; }
.passwort-zuruecksetzen form .widget.widget-submit input[type="submit"] { min-width: 200px; background: var(--primary-color-rbg); font-size: 18px; padding: 0px 50px; color: var(--white-color); display: inline-block; margin-top: 20px; height: 50px; line-height: 50px; border: none; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; }
.passwort-zuruecksetzen form .widget.widget-submit input[type="submit"]:hover { background: var(--primary-color-rbg-hover); }

/***********************
PRODUKTDETAILS
***********************/
.produktdetails > .row:nth-child(2) { margin-top: 20px; }
.produktdetails .product_image_container .big_image { border: 1px solid var(--first-grey-color); padding: 10px; min-height: 300px; max-height: 300px; background-position: 50% 50%; cursor: zoom-in; background-size: 0 0; overflow: hidden; text-align: center; }
.produktdetails .product_image_container .big_image img { width: auto; max-width: 100%; height: 100%; max-height: 280px; transition: opacity 0.3s; }
.produktdetails .product_image_container .big_image:hover { background-size: auto; }
.produktdetails .product_image_container .big_image:hover img { opacity: 0; transition: opacity 0.0s; }
.produktdetails .more-images { display: flex; flex-wrap: nowrap; }
.produktdetails .more-images .img-container { width: 33.3%; border: 1px solid var(--first-grey-color); margin-top: 15px; margin-right: 15px; padding: 5px; text-align: center; overflow: hidden; }
.produktdetails .more-images .img-container:last-child { margin-right: 0; }
.produktdetails .more-images .img-container img { max-width: 100%; height: auto; max-height: 83px; }
.produktdetails h1 { width: 80%; font-size: 2em; margin-bottom: 0.5em; color: var(--fifth-grey-color); }
.produktdetails .artnr { color: var(--third-grey-color); display: inline-block; }
.produktdetails .artnr p { display: inline-block; }
.produktdetails .artnr #mainart { display: inline-block; }
.produktdetails p.bestand { padding-bottom: 15px; border-bottom: 1px solid var(--first-grey-color); }
.produktdetails p.bestand .fa-color-red { color: var(--red-color); }
.produktdetails p.bestand .fa-color-green { color: var(--primary-color); }
.produktdetails p.bestand .fa-color-orange { color: var(--orange-color); }
.produktdetails p.bestand span { font-weight: bold; }
.produktdetails .variante { padding-bottom: 15px; border-bottom: 1px solid var(--first-grey-color); }
.produktdetails .variante > p { margin: 0 0 10px 0; }
.produktdetails .variante select.var_picker { display: none; }
.produktdetails .variante #a { padding: 0px; margin: 0px; }
.produktdetails .variante #a img, .produktdetails .variante .btn-select img { width: auto; height: 30px; }
.produktdetails .variante #a li { list-style: none; padding: 0px 10px 0px 5px; border-bottom: 1px solid #ccc; line-height: 40px; text-align: left; display: flex; justify-content: space-between; }
.produktdetails .variante #a li:hover { background-color: #F4F3F3; }
.produktdetails .variante #a li:last-child { border-bottom: none; }
.produktdetails .variante #a li span.left-side { margin: 0; }
.produktdetails .variante #a li span.right-side { margin: 0; }
.produktdetails .variante #a li .img { width: 50px; min-width: 50px; max-width: 50px; margin: 0; text-align: center; display: inline-block; }
.produktdetails .variante #a li .img img { width: auto; height: 30px; }
.produktdetails .variante #a li .txt { font-weight: bold; line-height: 30px; }
.produktdetails .variante #a li .variant_price { margin: 0; line-height: 40px; }
.produktdetails .variante #a li .art-nr { margin: 0; }
.produktdetails .variante #a li .fas { display: none; }
.produktdetails .variante #a li span, .produktdetails .variante .btn-select li span { margin-left: 10px; }
.produktdetails .variante .lang-select { position: relative; }
.produktdetails .variante .b { background: var(--first-grey-color); position: absolute; display: none; width: 100%; max-height: 200px; box-shadow: 0 6px 12px rgba(0,0,0,.175); z-index: 9999; overflow-y: scroll; }
.produktdetails .variante .open { display: show !important; }
.produktdetails .variante .btn-select { width: 100%; height: 40px; padding: 0px 10px 0px 5px; border-radius: 0px; background-color: var(--white-color); border: 1px solid var(--first-grey-color); }
.produktdetails .variante .btn-select li { width: 100%; list-style: none; text-align: left; float: left; padding-bottom: 0px; display: flex; justify-content: space-between; }
.produktdetails .variante .btn-select li span { display: inline; vertical-align: top; line-height: 40px;  }
.produktdetails .variante .btn-select li span.left-side { margin: 0; }
.produktdetails .variante .btn-select li span.right-side { margin: 0; }
.produktdetails .variante .btn-select li .img { width: 50px; min-width: 50px; max-width: 50px; margin: 0 10px 0 0; text-align: center; display: inline-block; }
.produktdetails .variante .btn-select li .txt { max-width: 350px; font-weight: bold; margin: 0; max-height: 40px; display: inline-block; overflow: hidden; }
.produktdetails .variante .btn-select li .variant_price { margin: 0; }
.produktdetails .variante .btn-select li .art-nr { margin: 0; }
.produktdetails .variante .btn-select li .fas { margin-left: 10px; line-height: 40px; }
.produktdetails .variante .btn-select:hover li { margin-left: 0px; }
.produktdetails .variante .btn-select:hover { background-color: var(--white-color); border: 1px solid var(--seventh-grey-color); }
.produktdetails .variante .btn-select:focus { outline:none; }
.produktdetails .grey_box { background: var(--first-grey-color); padding: 20px; }
.produktdetails .grey_box p.plslogin { margin: 0; }
.produktdetails .grey_box .product_price .price { font-size: 1.375em; font-weight: bold; }
.produktdetails .grey_box .price_brutto { font-size: 0.875em; }
.produktdetails .grey_box form .anzahl { margin-top: 20px; }
.produktdetails .grey_box form .anzahl .qtyminus, .produktdetails .grey_box form .anzahl .qtyplus { width: 38px; height: 38px; line-height: 38px; border: 1px solid var(--seventh-grey-color);
display: block;	text-align: center;	float: left; cursor: pointer; }
.produktdetails .grey_box form .anzahl .qtyminus:hover, .produktdetails .grey_box form .anzahl .qtyplus:hover { background: var(--white-color); }
.produktdetails .grey_box form .anzahl input#menge { width: calc(100% - 76px); padding: 8px 5px 7px 5px; border-top: 1px solid var(--seventh-grey-color);	border-bottom: 1px solid var(--seventh-grey-color); border-left: none; border-right: none; text-align: center; display: block; float: left; margin: 0; }
.produktdetails .grey_box form a.add-to-cart { background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); display: block; margin-top: 20px; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; }
.produktdetails .grey_box form a.add-to-cart:hover { background: var(--primary-color-rbg-hover); }
.produktdetails .grey_box form a.add-to-cart i { margin-left: 10px; }
.produktdetails .grey_box.staffel { margin-top: 20px; background: var(--white-color); border: 1px solid var(--seventh-grey-color); }
.produktdetails .grey_box.staffel .head { font-size: 1.142857142857143em; font-weight: 700; margin-bottom: 10px; }
.produktdetails .grey_box.staffel table { width: 100%; }
.produktdetails .grey_box.staffel table thead tr th { width: 70%; padding: 2px 0; border-bottom: 1px solid var(--seventh-grey-color); }
.produktdetails .grey_box.staffel table thead tr th:nth-child(2) { width: 30%; }
.produktdetails .grey_box.staffel table tbody tr td { padding: 2px 0; border-bottom: 1px solid var(--seventh-grey-color); color: var(--third-grey-color); }
.produktdetails .grey_box.staffel .info { font-size: .875em; margin-top: 10px; color: var(--third-grey-color); }
.produktdetails .grey_box.staffel.bruttopreis table thead tr th { width: 33.3333%; }
.produktdetails .grey_box.staffel table thead tr th:nth-child(2) { width: 33.3333%; }
.produktdetails .col-lg-6 .head { background: var(--first-grey-color); color: #171717; font-size: 14px; font-weight: bold; line-height: 38px; padding: 0px 15px; margin-bottom: 15px; }
.produktdetails .col-lg-6 .accordion { width: 100%; }
.produktdetails .col-lg-6 .accordion .accordion-item { border: none; }
.produktdetails .col-lg-6 .accordion .accordion-item .accordion-header .accordion-button { background: var(--first-grey-color); font-size: 14px; font-weight: bold; padding: 0 15px; margin-bottom: 15px; line-height: 38px; position: relative; }
.produktdetails .col-lg-6 .accordion .accordion-item .accordion-header .accordion-button::after { font-family: "Font Awesome 5 Free"; position: absolute; display: block; right: 15px; }
.produktdetails .col-lg-6 .accordion .accordion-item .accordion-header .accordion-button:focus { box-shadow: none; }
.produktdetails .col-lg-6 .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) { color: #171717; box-shadow: none; }
.produktdetails .col-lg-6 .accordion .accordion-body { padding: 0; margin-bottom: 20px; }
.produktdetails .col-lg-6 .accordion .accordion-body span { font-family: var(--font-family) !important; font-size: 14px !important; }
.produktdetails .col-lg-6 .accordion .accordion-body a.datasheet { background: var(--primary-color-rbg); color: var(--white-color); width: auto; display: inline-block; padding: 10px 20px; transition: all ease 0.3s; }
.produktdetails .col-lg-6 .accordion .accordion-body a.datasheet:hover { background: var(--primary-color-rbg-hover); color: var(--white-color); transition: all ease 0.3s; }

/***********************
SUCHE
***********************/
.search_result h1 { font-size: 2em; color: var(--fifth-grey-color); margin-bottom: 20px; }
.search_result h1 span { font-size: 0.7142857142857143em; color: var(--sixth-grey-color); margin-left: 20px; }
.search_result .options { text-align: right; padding-top: 20px; margin-bottom: 30px; border-top: 1px solid var(--first-grey-color); }
.search_result .options.down { margin-bottom: 0; }
.search_result .options .col-lg-6:first-child { display: flex; }
.search_result .options .sort_filter .select-wrapper { width: 170px; position: relative; background: var(--first-grey-color); border-radius: 4px; margin-right: 10px; }
.search_result .options .sort_filter .select-wrapper::before { font-family: "Font Awesome 5 Pro"; font-weight: 900; content: "\f078"; position: absolute; right: 10px; top: 5px; color: var(--fifth-grey-color); }
.search_result .options .sort_filter select { width: 170px; background: transparent; -moz-appearance: none; -webkit-appearance: none; appearance: none; border: none; padding: 4px 10px; }
.search_result .options .count_filter .select-wrapper { width: 170px; position: relative; background: var(--first-grey-color); border-radius: 4px; }
.search_result .options .count_filter .select-wrapper::before { font-family: "Font Awesome 5 Pro"; font-weight: 900; content: "\f078"; position: absolute; right: 10px; top: 5px; color: var(--fifth-grey-color); }
.search_result .options .count_filter select { width: 170px; background: transparent; -moz-appearance: none; -webkit-appearance: none; appearance: none; border: none; padding: 4px 10px; }

.search_result .options .page_info { display: inline-block; }
.search_result .options .page_info ul { margin: 0; padding: 0; list-style: none; }
.search_result .options .page_info ul li { display: inline-block; }
.search_result .options .page_info ul li a { width: 26px; font-size: 14px; font-weight: 400; color: var(--fifth-grey-color); display: block; border-bottom: 2px solid var(--white-color); text-align: center; }
.search_result .options .page_info ul li:hover a { font-size: 18px; font-weight: bold; color: var(--primary-color); display: block; border-bottom: 2px solid var(--white-color); }
.search_result .options .page_info ul li.active a { width: 26px; font-size: 18px; font-weight: bold; color: var(--primary-color); display: block; border-bottom: 2px solid var(--primary-color); text-align: center; }

.search_result .options .layout-event { display: inline-block; margin-left: 60px; }
.search_result .options .layout-event p { display: inline-block; margin: 0 5px 0 0; color: var(--fifth-grey-color); font-weight: bold; }
.search_result .options .layout-event span { padding: 0px 3px 3px 3px; border-bottom: 2px solid var(--white-color); font-size: 18px; color: var(--fifth-grey-color); cursor: pointer; }
.search_result .options .layout-event span:hover { color: var(--primary-color); }
.search_result .options .layout-event span.active { color: var(--primary-color); border-bottom: 2px solid var(--primary-color); }
.search_result .searchresult { display: none; }
.search_result .searchresult.show { display: block; }
/*SUCHE - GRID*/
.search_result .searchresult.grid-view .products { display: flex; flex-wrap: wrap; }
.search_result .searchresult.grid-view .item { width: calc(25% - 15px); margin: 0 20px 20px 0; border: 1px solid var(--first-grey-color); transition: all 0.4s ease; }
.search_result .searchresult.grid-view .item:hover { -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05); -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05); box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05); transition: all 0.4s ease; }
.search_result .searchresult.grid-view .item:nth-child(4n) { margin-right: 0px; }
.search_result .searchresult.grid-view .item .img { text-align: center; width: 100%; padding: 10px; min-height: 288px; }
.search_result .searchresult.grid-view .item .img img { width: auto; max-width: 100%; height: 100%; max-height: 268px; }
.search_result .searchresult.grid-view .item .title { font-size: 1.071428571428571em; line-height: 1.4em; min-height: 52px; padding: 10px 15px 0 15px; margin: 0; font-weight: 600; color: var(--fifth-grey-color); display: block; }
.search_result .searchresult.grid-view .item .artnr { padding: 10px 15px 0px 15px; margin: 0; color: var(--sixth-grey-color); }
.search_result .searchresult.grid-view .item .description { padding: 10px 15px 0px 15px; margin: 0; color: var(--sixth-grey-color); }
.search_result .searchresult.grid-view .item .price { font-size: 18px; font-weight: bold; color: var(--fifth-grey-color); padding: 10px 15px 0px 15px; margin: 0; }
.search_result .searchresult.grid-view .item .price span { font-size: 14px; color: var(--fifth-grey-color); font-weight: 400; }
.search_result .searchresult.grid-view .item .brutto_price { font-size: 0.875em; color: var(--sixth-grey-color); padding: 0px 15px 0px 15px; margin: 0; }
.search_result .searchresult.grid-view .item .brutto_price.padbottom { padding: 0 15px 10px 15px; }
.search_result .searchresult.grid-view .item .brutto_price a { color: var(--primary-color); }
.search_result .searchresult.grid-view .item .status { padding: 10px 15px 10px 15px; line-height: 27px; margin: 0; font-weight: bold; text-align: left; }
.search_result .searchresult.grid-view .item .status.green { color: var(--primary-color); }
.search_result .searchresult.grid-view .item .status.red { color: var(--red-color); }
.search_result .searchresult.grid-view .item .status.orange { color: var(--orange-color); }
.search_result .searchresult.grid-view .item .addtocart { padding: 10px; }
.search_result .searchresult.grid-view .item .addtocart .qtnty-minus,
.search_result .searchresult.grid-view .item .addtocart .qtnty-plus { width: 38px; height: 38px; line-height: 38px; border: 1px solid var(--seventh-grey-color);
display: block;	text-align: center;	float: left; cursor: pointer; }
.search_result .searchresult.grid-view .item .addtocart .qtnty-minus:hover,
.search_result .searchresult.grid-view .item .addtocart .qtnty-plus:hover { background: var(--white-color); }
.search_result .searchresult.grid-view .item .addtocart input#menge { width: calc(100% - 76px); padding: 8px 5px 7px 5px; border-top: 1px solid var(--seventh-grey-color);	border-bottom: 1px solid var(--seventh-grey-color); border-left: none; border-right: none; text-align: center; display: block; float: left; margin: 0; }
.search_result .searchresult.grid-view .item .addtocart .submit-container #addcart_grid { width: 100%; background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); display: block; margin: 20px 0px 10px 0px; padding: 15px 0; line-height: 20px; border: 0; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; }
.search_result .searchresult.grid-view .item .addtocart .submit-container #addcart_grid:hover { background: var(--primary-color-rbg-hover); }
.search_result .searchresult.grid-view .item .addtocart .submit-container #addcart_grid i { margin-left: 10px; }
/*SUCHE - LISTE*/
.search_result .searchresult.list-view .item { border-bottom: 1px solid var(--first-grey-color); display: flex; flex-wrap: nowrap; padding: 10px 0px 20px 0px; margin-bottom: 10px; }
.search_result .searchresult.list-view .item:last-child { border-bottom: none; margin-bottom: 0; }
.search_result .searchresult.list-view .item .img { width: calc(25% - 14px); margin-right: 21px; min-height: 268px; text-align: center; }
.search_result .searchresult.list-view .item .img img { width: auto; max-width: 268px; height: auto; max-height: 268px; }
.search_result .searchresult.list-view .item .title_info { width: calc(50% - 14px); margin-right: 21px; }
.search_result .searchresult.list-view .item .title_info .title { font-size: 1.071428571428571em; padding: 10px 0px 0px 0px; margin: 0; font-weight: 600; color: var(--fifth-grey-color); display: block; }
.search_result .searchresult.list-view .item .title_info .description { padding: 10px 0px 0px 0px; margin: 0; color: var(--sixth-grey-color); }
.search_result .searchresult.list-view .item .title_info .status { padding: 10px 0px 0px 0px; margin: 0; font-weight: bold; text-align: left; }
.search_result .searchresult.list-view .item .title_info .status.green { color: var(--primary-color); }
.search_result .searchresult.list-view .item .title_info .status.red { color: var(--red-color); }
.search_result .searchresult.list-view .item .title_info .status.orange { color: var(--orange-color); }
.search_result .searchresult.list-view .item .grey_box { width: 25%; background: var(--first-grey-color); padding: 20px; }
.search_result .searchresult.list-view .item .grey_box p { margin: 0; }
.search_result .searchresult.list-view .item .grey_box .product_price .price { font-size: 18px; font-weight: bold; }
.search_result .searchresult.list-view .item .grey_box .price_brutto { font-size: 0.875em; }
.search_result .searchresult.list-view .item .grey_box .quantity { margin-top: 20px; }
.search_result .searchresult.list-view .item .grey_box .quantity .qtnty-minus,
.search_result .searchresult.list-view .item .grey_box .quantity .qtnty-plus { width: 38px; height: 38px; line-height: 38px; border: 1px solid var(--seventh-grey-color);
display: block;	text-align: center;	float: left; cursor: pointer; }
.search_result .searchresult.list-view .item .grey_box .quantity .qtnty-minus:hover,
.search_result .searchresult.list-view .item .grey_box .quantity .qtnty-plus:hover { background: var(--white-color); }
.search_result .searchresult.list-view .item .grey_box .quantity input#menge { width: calc(100% - 76px); padding: 8px 5px 7px 5px; border-top: 1px solid var(--seventh-grey-color);	border-bottom: 1px solid var(--seventh-grey-color); border-left: none; border-right: none; text-align: center; display: block; float: left; margin: 0; }
.search_result .searchresult.list-view .item .grey_box .quantity .submit-container #addcart_list { width: 100%; background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); display: block; margin-top: 20px; padding: 15px 0; line-height: 20px; border: 0; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; }
.search_result .searchresult.list-view .item .grey_box .quantity .submit-container #addcart_list:hover { background: var(--primary-color-rbg-hover); }
.search_result .searchresult.list-view .item .grey_box .quantity .submit-container #addcart_list i { margin-left: 10px; }
/*SUCHE - TABELLE*/
.search_result .searchresult.table-view table { width: 100%; margin-bottom: 20px; }
.search_result .searchresult.table-view table thead tr th { background: var(--primary-color); padding: 10px; }
.search_result .searchresult.table-view table thead tr th.last { min-width: 185px; width: 185px; }
.search_result .searchresult.table-view table tbody tr:nth-child(2n) td { background: var(--second-grey-color); }
.search_result .searchresult.table-view table tbody tr td a { padding: 10px; display: block; color: var(--fifth-grey-color); }
.search_result .searchresult.table-view table tbody tr td p { margin: 0; }
.search_result .searchresult.table-view table tbody tr td p.status i { margin-right: 5px; }
.search_result .searchresult.table-view table tbody tr td p.status.green i { color: var(--primary-color); }
.search_result .searchresult.table-view table tbody tr td p.status.red i { color: var(--red-color); }
.search_result .searchresult.table-view table tbody tr td p.status.orange i { color: var(--orange-color); }
.search_result .searchresult.table-view table tbody tr td .qtnty-minus,
.search_result .searchresult.table-view table tbody tr td .qtnty-plus { background: var(--second-grey-color); width: 31px; height: 31px; line-height: 31px; text-align: center; float: left; margin: 5px 0; cursor: pointer; color: var(--fifth-grey-color); transition: all 0.2s ease; }
.search_result .searchresult.table-view table tbody tr td .qtnty-minus:hover,
.search_result .searchresult.table-view table tbody tr td .qtnty-plus:hover { color: var(--primary-color); transition: all 0.2s ease; }
.search_result .searchresult.table-view table tbody tr td .qtnty-minus { margin-left: 10px; }
.search_result .searchresult.table-view table tbody tr td .quantity { float: left; }
.search_result .searchresult.table-view table tbody tr td .quantity input { width: 60px; border: 0; margin: 5px 0; line-height: 31px; background: var(--second-grey-color); text-align: center; }
.search_result .searchresult.table-view table tbody tr td .submit-container button { width: 31px; height: 31px; background-color: var(--primary-color-rbg); margin: 5px 0px 5px 5px; box-shadow: none; border: 0; transition: all 0.2s ease; }
.search_result .searchresult.table-view table tbody tr td .submit-container button:hover { background-color: var(--primary-color-rbg-hover); transition: all 0.2s ease; }
.search_result .searchresult.table-view table tbody tr td .submit-container button .default { top: -8px; }
.search_result .searchresult.table-view table tbody tr td .submit-container button .default i { margin-left: 0; }
.search_result .searchresult.table-view table tbody tr td .submit-container button .success { top: 7px; }
.search_result .searchresult.table-view table tbody tr td .submit-container button .success i { margin-left: 0; }

/***********************
WARENKORB
***********************/
.warenkorb-site .empty .head p { font-size: 16px; font-weight: 600; color: var(--fifth-grey-color); margin-bottom: 0px; display: block; }
.warenkorb-site .head p { font-size: 16px; font-weight: 600; color: var(--fifth-grey-color); margin-bottom: 30px; display: block; }
.warenkorb-site #loading { width: 100%; height: 100%; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: rgba(0, 0, 0, 0.5); z-index: 9999; overflow-y: hidden; }
.warenkorb-site #loading .box { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
.warenkorb-site #loading .box .lds-ring { float: left; position: relative; width: 40px; height: 40px; }
.warenkorb-site #loading .box .lds-ring div { box-sizing: border-box; display: block; position: absolute; width: 32px; height: 32px; margin: 4px; border: 4px solid var(--white-color); border-radius: 50%; animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; border-color: var(--white-color) transparent transparent transparent; }
.warenkorb-site #loading .box .lds-ring div:nth-child(1) { animation-delay: -0.45s; }
.warenkorb-site #loading .box .lds-ring div:nth-child(2) { animation-delay: -0.3s; }
.warenkorb-site #loading .box .lds-ring div:nth-child(3) { animation-delay: -0.15s; }
@keyframes lds-ring { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
.warenkorb-site #loading .box .text { float: left; color: var(--white-color); line-height: 40px; margin-left: 10px; }
.warenkorb-site .cart_box { box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); padding: 20px; }
.warenkorb-site .cart_box .product_pos { border-bottom: 1px solid var(--first-grey-color); margin-bottom: 25px; padding-bottom: 15px; }
.warenkorb-site .cart_box .product_pos:last-child { margin-bottom: 0px; }
.warenkorb-site .cart_box .product_pos .prod_img { width: 56px; margin-right: 10px; display: inline-block; vertical-align: top; }
.warenkorb-site .cart_box .product_pos .prod_img img { width: 100%; height: auto; }
.warenkorb-site .cart_box .product_pos .prod_info { width: calc(100% - 70px); display: inline-block; }
.warenkorb-site .cart_box .product_pos .prod_info > a { font-size: 1.071428571428571em; font-weight: bold; display: block; color: var(--fifth-grey-color); margin-bottom: 5px; }
.warenkorb-site .cart_box .product_pos .prod_info > a:hover { color: var(--primary-color); }
.warenkorb-site .cart_box .product_pos .prod_info p { margin-bottom: 5px; }
.warenkorb-site .cart_box .product_pos .prod_info p.price_einzel { font-size: 16px; font-weight: 600; }
.warenkorb-site .cart_box .product_pos .prod_info p.bestand { font-size: 0.875em; font-weight: 600; margin-top: 15px; margin-bottom: 0; }
.warenkorb-site .cart_box .product_pos .prod_info p.bestand.auflager { color: var(--primary-color); }
.warenkorb-site .cart_box .product_pos .prod_info p.bestand.nichtda { color: var(--red-color); }
.warenkorb-site .cart_box .product_pos .prod_quantity { width: 50%; float: left; vertical-align: top; }
.warenkorb-site .cart_box .product_pos .prod_quantity form input { max-width: 80px; border: 1px solid var(--seventh-grey-color); border-radius: 0px; text-align: center; -moz-appearance: textfield; }
.warenkorb-site .cart_box .product_pos .prod_quantity form input:focus { box-shadow: none; border: 1px solid var(--seventh-grey-color); }
.warenkorb-site .cart_box .product_pos .prod_quantity form .input-group-text { background: var(--first-grey-color); border: 1px solid var(--seventh-grey-color); border-radius: 0px; }
.warenkorb-site .cart_box .product_pos .prod_quantity form .on-change a { font-size: 0.875em; color: var(--fifth-grey-color); text-decoration: underline; margin-top: 5px; display: block; }
.warenkorb-site .cart_box .product_pos .prod_quantity form .on-change a i { margin-right: 5px; text-decoration: none; }
.warenkorb-site .cart_box .product_pos .prod_quantity form .on-change a:hover { color: var(--primary-color); }
.warenkorb-site .cart_box .product_pos .prod_summe { width: 50%; height: 100%; float: left; text-align: right; position: relative; }
.warenkorb-site .cart_box .product_pos .prod_summe .summe_price { font-size: 18px; font-weight: 600; }
.warenkorb-site .cart_box .product_pos .prod_summe .delete_pos { position: absolute; bottom: 0; right: 0; font-size: 0.875em; color: var(--fourth-grey-color); text-decoration: underline; cursor: pointer; }
.warenkorb-site .cart_box .product_pos .prod_summe .delete_pos i { margin-left: 5px; text-decoration: none; }
.warenkorb-site .cart_box .product_pos .prod_summe .delete_pos:hover { color: var(--fifth-grey-color); }
.warenkorb-site .cart_box .delete-cart .delete_complete_cart { font-size: 0.875em; color: var(--fourth-grey-color); text-decoration: underline; cursor: pointer; }
.warenkorb-site .cart_box .delete-cart .delete_complete_cart i { margin-right: 5px; text-decoration: none; }
.warenkorb-site .cart_box .delete-cart .delete_complete_cart:hover { color: var(--fifth-grey-color); }
.warenkorb-site .cart_box .cart_info ul { list-style: none; margin: 0 0 20px 0; padding: 0; }
.warenkorb-site .cart_box .cart_info ul li { border-bottom: 1px solid var(--first-grey-color); padding: 7px 0; }
.warenkorb-site .cart_box .cart_info ul li:first-child { padding: 0 0 7px 0; }
.warenkorb-site .cart_box .cart_info ul li:last-child { padding: 7px 0 0 0; border: none; }
.warenkorb-site .cart_box .cart_info ul li label { width: 50%; float: left; }
.warenkorb-site .cart_box .cart_info ul li p { width: 50%; float: left; margin: 0; text-align: right; }
.warenkorb-site .cart_box .cart_info ul li.netto,
.warenkorb-site .cart_box .cart_info ul li.endbetrag { font-size: 1.071428571428571em; font-weight: 600; }
.warenkorb-site .cart_box .cart_info .submit_container a { background: var(--primary-color-rbg); color: var(--white-color); font-size: 18px; font-weight: 600; height: 50px; line-height: 50px; display: block; text-align: center; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); cursor: pointer; transition: all 0.2s ease; }
.warenkorb-site .cart_box .cart_info .submit_container a:hover { background: var(--primary-color-rbg-hover); transition: all 0.2s ease; }
.warenkorb-site .cart_box .cart_info .submit_container a i { margin-left: 10px; }
.warenkorb-site p.footer { display: none; }

/**********************************************************************************************************
CHECKOUT
**********************************************************************************************************/
/*Checkout Steps*/
.checkout-steps ul { width: 100%; list-style: none; display: flex; flex-direction: row; flex-wrap: nowrap; padding: 0; margin: 0; overflow: hidden; }
.checkout-steps ul li { width: 20%; padding: 10px 20px; position: relative; color: var(--sixth-grey-color); }
.checkout-steps ul li::before { content: ""; width: 100%; position: absolute; background: var(--first-grey-color); right: 0px; top: 0; border-right: 2px solid var(--white-color); height: 21px; transform-origin: 0 100%; transform: skewX(22.5deg); z-index: 1; }
.checkout-steps ul li::after { content: ""; width: 100%; position: absolute; background: var(--first-grey-color); right: 0px; bottom: 0; border-right: 2px solid var(--white-color); height: 20px; transform-origin: 0 0; transform: skewX(-22.5deg); z-index: 1; }
.checkout-steps ul li span { z-index: 3; position: relative; }
.checkout-steps ul li span i { margin-right: 5px; }
.checkout-steps ul li a { color: var(--third-grey-color); }
.checkout-steps ul li a.disable { pointer-events: none; text-decoration: none; color: var(--sixth-grey-color); }
.checkout-steps ul li.check { background: var(--primary-color); color: var(--white-color); }
.checkout-steps ul li.check::before { background: var(--primary-color); }
.checkout-steps ul li.check::after { background: var(--primary-color); }
.checkout-steps ul li.check a { color: var(--white-color); }
.checkout-steps ul li.active { background: var(--primary-color); color: var(--third-grey-color); cursor: pointer; }
.checkout-steps ul li.active::before { background: var(--primary-color); }
.checkout-steps ul li.active::after { background: var(--primary-color); }
.checkout-steps ul li.active a { color: var(--third-grey-color); }
.checkout-steps ul li:last-child { background: var(--white-color); }

/*Fehlermeldung UST-ID*/
#error-root .alert,
#error-rechnungsadresse .alert,
#error-lieferadresse .alert { margin: 30px 0 0 0; }

/*Rechnungs- und Lieferanschrift*/
.rechnungsadresse .col-lg-12, .lieferadresse .col-lg-12, 
.rechnungsadresse .col-lg-6, .lieferadresse .col-lg-6 { margin-bottom: 10px; }
.rechnungsadresse .col-lg-6:nth-child(1), .lieferadresse .col-lg-6:nth-child(1) { padding-right: 5px; }
.rechnungsadresse .col-lg-6:nth-child(2), .lieferadresse .col-lg-6:nth-child(2) { padding-left: 5px; }
.checkout-adress .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-top: 30px; margin-bottom: 10px; }
.checkout-adress .head p { margin: 0; }
.checkout-adress .widget { position: relative; z-index: 1; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); }
.checkout-adress .widget label { position: absolute; z-index: 3; padding: 0 10px; top: 14px; left: 2px; cursor: text; transition: all .3s ease; }
.checkout-adress .widget input.active,
.checkout-adress .widget input:focus { border: 1px solid var(--seventh-grey-color); box-shadow: none; outline: none; }
.checkout-adress .widget label.active { top: 5px; font-size: 11px; transition: all .3s ease; }
.checkout-adress .widget input:not(:empty):valid + label { top: 5px; font-size: 11px; }
.checkout-adress .widget.widget-text input[type="text"] { width: 100%; position: relative; z-index: 2; padding: 20px 12px 5px 12px; border: 1px solid var(--first-grey-color); }
.checkout-adress .widget.widget-text input[type="text"]:focus { border: 1px solid var(--seventh-grey-color); box-shadow: none; outline: none; }
.checkout-adress .widget.widget-select select { background: transparent; width: 100%; position: relative; z-index: 2; padding: 20px 12px 9px 12px; border: 1px solid var(--first-grey-color); }
.checkout-adress .widget.widget-text label { position: absolute; z-index: 3; padding: 0px 10px; top: 14px; left: 2px; cursor: text; }
.checkout-adress .widget.widget-text label.active { top: 5px; font-size: 11px; }
.checkout-adress .widget.widget-select label { position: absolute; font-size: 11px; z-index: 3; padding: 0 10px; top: 5px; left: 2px; cursor: text; }
.checkout-adress .widget.widget-select label.active { top: 5px; font-size: 11px; }
.checkout-adress .widget-checkbox { border: 1px solid var(--first-grey-color); padding: 12px 12px 13px 12px; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); }
.checkout-adress .widget-checkbox label { padding-left: 5px; }
.checkout-adress .abweichende_lieferadresse { display:none; margin: 0; }

/*Validation*/
.checkout-adress input:user-invalid,
.checkout-adress select:user-invalid { border-color: var(--red-color) !important; }

/*Versand- und Zahlung*/
.versandart .head, .zahlungsart .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-top: 30px; margin-bottom: 10px; }
.versandart .head p, .zahlungsart .head p { margin: 0; }
.versandart .widget-radio, .zahlungsart .widget-radio { border: 1px solid var(--first-grey-color); padding: 0px 12px 0px 12px; margin-bottom: 10px; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); display: flex; }
.versandart .widget-radio input, .zahlungsart .widget-radio input { margin-right: 5px; }
.versandart .widget-radio label, .zahlungsart .widget-radio label { width: calc(100% - 20px); padding: 12px 0 13px 0; }
.versandart .widget-radio label span, .zahlungsart .widget-radio label span { float: right; }

/*Prüfen und Bestellen*/
.checkout-pruefen-bestellen .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 20px; }
.checkout-pruefen-bestellen .head.martop20 { margin-top: 20px; }
.checkout-pruefen-bestellen .head.martop30 { margin-top: 30px; }
.checkout-pruefen-bestellen .head p { margin: 0; }
.checkout-pruefen-bestellen .head p a { float: right; font-size: 12px; line-height: 21px; font-weight: 400; color: var(--sixth-grey-color); }
.checkout-pruefen-bestellen .head p a i { margin-right: 5px; }
.checkout-pruefen-bestellen .head p a:hover { color: var(--eight-grey-color); }
.checkout-pruefen-bestellen .widget abbr { color: var(--red-color); }
.checkout-pruefen-bestellen .widget.widget-info { border: 1px solid var(--first-grey-color); padding: 12px 12px 13px 12px; margin-bottom: 0px; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); }
.checkout-pruefen-bestellen .widget.widget-info.re-anschrift,
.checkout-pruefen-bestellen .widget.widget-info.li-anschrift { min-height: 132px; }
.checkout-pruefen-bestellen .widget.widget-info p { margin: 0; }
.checkout-pruefen-bestellen .widget.widget-info p span { float: right; font-size: 12px; line-height: 21px; }
.checkout-pruefen-bestellen .widget.widget-info label { width: 100%; }
.checkout-pruefen-bestellen .widget.widget-info label span { float: right; }
.checkout-pruefen-bestellen .widget.widget-text { position: relative; z-index: 1; margin-bottom: 10px; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); }
.checkout-pruefen-bestellen .widget.widget-text input[type="text"] { width: 100%; position: relative; z-index: 2; padding: 20px 12px 5px 12px; border: 1px solid var(--first-grey-color); }
.checkout-pruefen-bestellen .widget.widget-text label { position: absolute; z-index: 3; padding: 0px 10px; top: 14px; left: 2px; cursor: text; transition: all .3s ease; }
.checkout-pruefen-bestellen .widget.widget-text label.active { top: 5px; font-size: 11px; transition: all .3s ease; }
.checkout-pruefen-bestellen .widget.information p { margin-bottom: 10px; }
.checkout-pruefen-bestellen .widget.widget-checkbox { border: 1px solid var(--first-grey-color); padding: 12px 12px 13px 12px; margin-bottom: 10px; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); }
.checkout-pruefen-bestellen .widget.widget-checkbox label { display: block; }
.checkout-pruefen-bestellen .widget.widget-checkbox label input { margin-right: 5px; }
.checkout-pruefen-bestellen .widget.widget-checkbox label a { color: var(--eight-grey-color); text-decoration: underline; }
.checkout-pruefen-bestellen .cart { padding: 12px; border: 1px solid var(--first-grey-color); box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); }
.checkout-pruefen-bestellen .cart ul { list-style: none; padding: 0; margin: 0; }
.checkout-pruefen-bestellen .cart ul.top, .checkout-pruefen-bestellen .cart ul.pos { display: flex; }
.checkout-pruefen-bestellen .cart ul.top li { font-weight: 600; border-bottom: 1px solid var(--first-grey-color); margin-bottom: 10px; font-size: 1.071428571428571em; } 
.checkout-pruefen-bestellen .cart ul.pos li { border-bottom: 1px solid var(--first-grey-color); padding-bottom: 10px; margin-bottom: 10px; }
.checkout-pruefen-bestellen .cart ul.top li:nth-child(1), .checkout-pruefen-bestellen .cart ul.pos li:nth-child(1) { width: 50%; }
.checkout-pruefen-bestellen .cart ul.top li:nth-child(2), .checkout-pruefen-bestellen .cart ul.pos li:nth-child(2) { width: 20%; text-align: left; }
.checkout-pruefen-bestellen .cart ul.top li:nth-child(3), .checkout-pruefen-bestellen .cart ul.pos li:nth-child(3) { width: 10%; text-align: left; }
.checkout-pruefen-bestellen .cart ul.top li:nth-child(4), .checkout-pruefen-bestellen .cart ul.pos li:nth-child(4) { width: 20%; text-align: right; }
.checkout-pruefen-bestellen .cart ul.pos li span { font-size: 12px; color: var(--sixth-grey-color); display: block; }
.checkout-pruefen-bestellen .cart ul.pos.last li { border-bottom: 3px double var(--first-grey-color); }
.checkout-pruefen-bestellen .cart ul.bottom li { border-bottom: 1px solid var(--first-grey-color); padding-bottom: 7px; margin-bottom: 7px; }
.checkout-pruefen-bestellen .cart ul.bottom li:last-child { border-bottom: none; padding: 0; margin: 0; }
.checkout-pruefen-bestellen .cart ul.bottom li label { width: 100%; }
.checkout-pruefen-bestellen .cart ul.bottom li.netto label,
.checkout-pruefen-bestellen .cart ul.bottom li.endbetrag label { font-size: 1.071428571428571em; font-weight: 600; }
.checkout-pruefen-bestellen .cart ul.bottom li label span { float: right; }

.checkout-submit .col-lg-12 { margin: 0; }
.checkout-submit .widget-submit { text-align: left; margin-top: 10px; box-shadow: none; }
.checkout-submit .widget-submit a { padding: 0px 50px; border: 0; background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); height: 50px; line-height: 50px; text-align: center; font-weight: 600; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); display: inline-block; }
.checkout-submit .widget-submit a i { margin-left: 10px; }
.checkout-submit .widget-submit a:hover { background: var(--primary-color-rbg-hover); }
.checkout-submit .widget-submit button { padding: 0px 50px; border: 0; border-radius: 0; background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); height: 50px; line-height: 50px; text-align: center; font-weight: 600; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); display: inline-block; }
.checkout-submit .widget-submit button i { margin-left: 10px; }
.checkout-submit .widget-submit button:hover { background: var(--primary-color-rbg-hover); }

/*Bestellung abgeschlossen*/
.order-finished h1 { font-size: 2em; color: var(--fifth-grey-color); margin: 30px 0; }
.order-finished p { margin: 0; }
.order-finished p.mb10 { margin-bottom: 10px; }
.order-finished p.mb20 { margin-bottom: 20px; }
.order-finished p a { background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); display: inline-block; margin-top: 20px; height: 50px; line-height: 50px; border: none; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; padding: 0px 30px; transition: all 0.2s ease; }
.order-finished p a:hover { background: var(--primary-color-rbg-hover); transition: all 0.2s ease; }

/**********************************************************************************************************
KUNDENKONTO: ÜBERSICHT
**********************************************************************************************************/
.kundenkonto h1 { font-size: 2em; color: var(--fifth-grey-color); margin: 0 0 30px 0; }
.kundenkonto .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 20px; }
.kundenkonto .head p { margin: 0; }
.kundenkonto .row .data.meine-daten { margin-bottom: 20px; }
.kundenkonto .row .data.meine-bestellungen { margin-top: 20px; }
.kundenkonto .row .data .box { box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); padding: 20px; }
.kundenkonto .row .col-lg-9 .data .box p { margin: 0; }
.kundenkonto .row .col-lg-9 .data .box .item label { min-width: 150px; display: inline-block; }
.kundenkonto .row .col-lg-9 .data .box .item .data { display: inline-block; }
.kundenkonto .row .col-lg-3 .data .box p { margin: 0; max-height: 21px; overflow: hidden; }
.kundenkonto .row .col-lg-3 .data .box .item label { min-width: 120px; display: inline-block; }
.kundenkonto .row .col-lg-3 .data .box .item .data { display: inline-block; }
.kundenkonto .row .data .box a.navbar-item { border-top: 1px solid var(--first-grey-color); margin: 25px 0 0 0; padding-top: 15px; color: var(--fourth-grey-color); font-size: 0.875em; text-decoration: underline; cursor: pointer; display: block; }
.kundenkonto .row .data .box a.navbar-item:hover { color: var(--fifth-grey-color); }
.kundenkonto .row:nth-child(3) { margin-top: 25px; }
.kundenkonto .row .data .box p.info-text { margin-top: 20px; font-size: 12px; height: auto; max-height: none; line-height: 16px; }

/**********************************************************************************************************
KUNDENKONTO: PASSWORT ÄNDERN
**********************************************************************************************************/
.kundenkonto .row.change-pw-data { margin-top: 0; }
.kundenkonto .change-pw .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 20px; }
.kundenkonto .change-pw .head p { margin: 0; }
.kundenkonto .change-pw .box { padding: 0; }
.kundenkonto .change-pw .box p { margin: 0; }
.kundenkonto .change-pw .box p label { min-width: 150px; display: inline-block; }
.kundenkonto .change-pw .box p { margin: 0; max-height: 21px; overflow: hidden; }
.kundenkonto .change-pw .box p label { min-width: 120px; display: inline-block; }
.kundenkonto .change-pw .box .widget { position: relative; z-index: 1; margin-bottom: 10px; }
.kundenkonto .change-pw .box .widget label { position: absolute; z-index: 3; padding: 0 10px; top: 14px; left: 2px; cursor: text; transition: all .3s ease; }
.kundenkonto .change-pw .box .widget input, .kundenkonto .change-pw .box .widget input.active,
.kundenkonto .change-pw .box .widget input:focus { border: 1px solid var(--seventh-grey-color); box-shadow: none; outline: none; }
.kundenkonto .change-pw .box .widget label.active { top: 5px; font-size: 11px; transition: all .3s ease; }
.kundenkonto .change-pw .box .widget input:valid + label { top: 5px; font-size: 11px; }
.kundenkonto .change-pw .box input { width: 100%; position: relative; z-index: 2; padding: 20px 12px 5px 12px; border: 1px solid var(--first-grey-color); }
.kundenkonto .change-pw .box input:focus { border: 1px solid var(--seventh-grey-color); box-shadow: none; outline: none; }
.kundenkonto .change-pw .submit_container { display: flex; justify-content: space-between; }
.kundenkonto .change-pw .submit_container button#button_save { background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); display: inline-block; margin-top: 20px; height: 50px; line-height: 50px; border: none; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; padding: 0px 30px; transition: all 0.2s ease; }
.kundenkonto .change-pw .submit_container button#button_save:hover { background: var(--primary-color-rbg-hover); transition: all 0.2s ease; }
.kundenkonto .change-pw .submit_container a { background: transparent; font-size: 14px; color: var(--fourth-grey-color); display: inline-block; margin-top: 20px; height: 50px; line-height: 50px; border: none; text-align: center; cursor: pointer; padding: 0px 30px; transition: all 0.2s ease; }
.kundenkonto .change-pw .submit_container a:hover { color: var(--fifth-grey-color); transition: all 0.2s ease; }

/**********************************************************************************************************
KUNDENKONTO: MEINE DATEN
**********************************************************************************************************/
.kundenkonto .row.edit-anp-data { margin-top: 0; }
.kundenkonto .edit-anpdata .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 20px; }
.kundenkonto .edit-anpdata .head p { margin: 0; }
.kundenkonto .edit-anpdata .box { padding: 0; }
.kundenkonto .edit-anpdata .box p { margin: 0; }
.kundenkonto .edit-anpdata .box p label { min-width: 150px; }
.kundenkonto .edit-anpdata .box .widget { position: relative; z-index: 1; margin-bottom: 10px; }
.kundenkonto .edit-anpdata .box .widget label { position: absolute; z-index: 3; padding: 0 10px; top: 14px; left: 2px; cursor: text; transition: all .3s ease; }
.kundenkonto .edit-anpdata .box .widget select, .kundenkonto .edit-anpdata .box .widget select:focus,
.kundenkonto .edit-anpdata .box .widget input, .kundenkonto .edit-anpdata .box .widget input.active,
.kundenkonto .edit-anpdata .box .widget input:focus { border: 1px solid var(--seventh-grey-color); box-shadow: none; outline: none; }
.kundenkonto .edit-anpdata .box .widget label.active { top: 5px; font-size: 11px; transition: all .3s ease; }
.kundenkonto .edit-anpdata .box .widget input:valid + label { top: 5px; font-size: 11px; }
.kundenkonto .edit-anpdata .box input { width: 100%; position: relative; z-index: 2; padding: 20px 12px 5px 12px; border: 1px solid var(--first-grey-color); }
.kundenkonto .edit-anpdata .box input:focus { border: 1px solid var(--seventh-grey-color); box-shadow: none; outline: none; }
.kundenkonto .edit-anpdata .box .widget.anrede label { position: absolute; font-size: 11px; z-index: 3; padding: 0 10px; top: 5px; left: 2px; cursor: text; }
.kundenkonto .edit-anpdata .box .widget.anrede label.active { top: 5px; font-size: 11px; }
.kundenkonto .edit-anpdata .box select { background: transparent; width: 100%; position: relative; z-index: 2; padding: 20px 12px 9px 12px; border: 1px solid var(--first-grey-color); }
.kundenkonto .edit-anpdata .submit_container { display: flex; justify-content: space-between; }
.kundenkonto .edit-anpdata .submit_container button#button_save { background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); display: inline-block; margin-top: 20px; height: 50px; line-height: 50px; border: none; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; padding: 0px 30px; transition: all 0.2s ease; }
.kundenkonto .edit-anpdata .submit_container button#button_save:hover { background: var(--primary-color-rbg-hover); transition: all 0.2s ease; }
.kundenkonto .edit-anpdata .submit_container a { background: transparent; font-size: 14px; color: var(--fourth-grey-color); display: inline-block; margin-top: 20px; height: 50px; line-height: 50px; border: none; text-align: center; cursor: pointer; padding: 0px 30px; transition: all 0.2s ease; }
.kundenkonto .edit-anpdata .submit_container a:hover { color: var(--fifth-grey-color); transition: all 0.2s ease; }

/**********************************************************************************************************
KUNDENKONTO: MEINE BESTELLUNGEN
**********************************************************************************************************/
.meinebestellungen h1 { font-size: 2em; color: var(--fifth-grey-color); margin-bottom: 30px; }
.meinebestellungen #loading { width: 100%; height: 100%; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: rgba(0, 0, 0, 0.5); z-index: 9999; overflow-y: hidden; }
.meinebestellungen #loading .box { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
.meinebestellungen #loading .box .lds-ring { float: left; position: relative; width: 40px; height: 40px; }
.meinebestellungen #loading .box .lds-ring div { box-sizing: border-box; display: block; position: absolute; width: 32px; height: 32px; margin: 4px; border: 4px solid var(--white-color); border-radius: 50%; animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; border-color: var(--white-color) transparent transparent transparent; }
.meinebestellungen #loading .box .lds-ring div:nth-child(1) { animation-delay: -0.45s; }
.meinebestellungen #loading .box .lds-ring div:nth-child(2) { animation-delay: -0.3s; }
.meinebestellungen #loading .box .lds-ring div:nth-child(3) { animation-delay: -0.15s; }
@keyframes lds-ring { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
.meinebestellungen #loading .box .text { float: left; color: var(--white-color); line-height: 40px; margin-left: 10px; }
.meinebestellungen .sort-filter-orders { display: flex; flex-wrap: nowrap; flex-flow: row; justify-content: space-between; }
.meinebestellungen .sort-filter-orders .right { display: flex; flex-wrap: nowrap; flex-flow: row; }
.meinebestellungen .sort-filter-orders .left label, 
.meinebestellungen .sort-filter-orders .right label { font-weight: bold; margin-right: 5px; }
.meinebestellungen .sort-filter-orders .right .widget-vonbelzeitraum, 
.meinebestellungen .sort-filter-orders .right .widget-bisbelzeitraum { margin-right: 30px; }
.meinebestellungen .sort-filter-orders .right .widget-vonbelzeitraum input, 
.meinebestellungen .sort-filter-orders .right .widget-bisbelzeitraum input { padding: 4px 10px; background: transparent; border: none; border-radius: 4px; }
.meinebestellungen .sort-filter-orders .select-input { width: auto; position: relative; background: var(--first-grey-color); border-radius: 4px; display: inline-block; }
.meinebestellungen .sort-filter-orders .select-wrapper { width: auto; position: relative; background: var(--first-grey-color); border-radius: 4px; display: inline-block; }
.meinebestellungen .sort-filter-orders .select-wrapper.w80 { width: 80px; }
.meinebestellungen .sort-filter-orders .select-wrapper.w120 { width: 120px; }
.meinebestellungen .sort-filter-orders .select-wrapper.w150 { width: 150px; }
.meinebestellungen .sort-filter-orders .select-wrapper.w170 { width: 170px; }
.meinebestellungen .sort-filter-orders .select-wrapper::before { font-family: "Font Awesome 5 Pro"; font-weight: 900; content: "\f078"; position: absolute; right: 10px; top: 5px; color: var(--fifth-grey-color); }
.meinebestellungen .sort-filter-orders .select-wrapper select { width: 100%; background: transparent; -moz-appearance: none; -webkit-appearance: none; appearance: none; border: none; padding: 4px 10px; }
.meinebestellungen .sort-filter-orders button { background: var(--primary-color-rbg); color: var(--fifth-grey-color); font-weight: bold; border-radius: 4px; padding: 4px 10px; border: 0; }
.meinebestellungen .sort-filter-orders button:hover { background: var(--primary-color-rbg-hover); }
.meinebestellungen .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-top: 15px; margin-bottom: 20px; }
.meinebestellungen .head p { margin: 0; }
.meinebestellungen .bel-container .widget-beleg { background: var(--white-color); box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); padding: 20px; margin-bottom: 20px; }
.meinebestellungen .bel-container .widget-beleg:last-child { margin-bottom: 0; }
.meinebestellungen .bel-container .widget-beleg .vorgangsnr p { font-size: 16px; font-weight: 600; color: var(--fifth-grey-color); margin-bottom: 30px; display: block; }
.meinebestellungen .bel-container .widget-beleg .belstatus p { font-size: 16px; font-weight: 600; color: var(--primary-color); margin-bottom: 30px; display: block; text-align: right; }
.meinebestellungen .bel-container .widget-beleg .info p { margin: 0; }
.meinebestellungen .bel-container .widget-beleg .info p span { width: 150px; display: inline-block; }
.meinebestellungen .bel-container .widget-beleg .toggle-btn > p { border-top: 1px solid var(--first-grey-color); margin: 25px 0 0 0; padding-top: 15px; color: var(--fourth-grey-color); font-size: 0.875em; text-decoration: none; cursor: pointer; position: relative; }
.meinebestellungen .bel-container .widget-beleg .toggle-btn > p.toggleof::before { font-family: "Font Awesome 5 Pro"; font-weight: 900; content: "\f061"; padding-right: 5px; }
.meinebestellungen .bel-container .widget-beleg .toggle-btn > p.toggleof.toggleon::before { font-family: "Font Awesome 5 Pro"; font-weight: 900; content: "\f063"; padding-right: 5px; }
.meinebestellungen .bel-container .widget-beleg .toggle-btn > p.toggleof.toggleon,
.meinebestellungen .bel-container .widget-beleg .toggle-btn > p:hover { color: var(--fifth-grey-color); }
.meinebestellungen .bel-container .widget-beleg .datainfo { border-top: 1px solid var(--first-grey-color); margin: 15px 0 0 0; padding-top: 25px; display: none; }
.meinebestellungen .bel-container .widget-beleg .datainfo.show { display: block; }
.meinebestellungen .bel-container .widget-beleg .datainfo .top { width: 100%; display: flex; flex-flow: row; flex-wrap: nowrap; }
.meinebestellungen .bel-container .widget-beleg .datainfo .top .item { background: var(--first-grey-color); border-right: 1px solid var(--white-color); border-bottom: 1px solid var(--white-color); padding: 8px 12px; }
.meinebestellungen .bel-container .widget-beleg .datainfo .top .item.steuerspalte { width: 5%; }
.meinebestellungen .bel-container .widget-beleg .datainfo .top .item.anzahl { width: 7.5%; }
.meinebestellungen .bel-container .widget-beleg .datainfo .top .item.bezeichnung { width: 30%; }
.meinebestellungen .bel-container .widget-beleg .datainfo .top .item.einzelpreis { width: 15%; }
.meinebestellungen .bel-container .widget-beleg .datainfo .top .item.gesamtpreis { width: 15%; }
.meinebestellungen .bel-container .widget-beleg .datainfo .top .item.bestellen { width: 27.5%; border-right: 0; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos { width: 100%; display: flex; flex-flow: row; flex-wrap: nowrap; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item { background: var(--white-color); border-right: 1px solid var(--first-grey-color); border-bottom: 1px solid var(--first-grey-color); padding: 8px 12px; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.steuerspalte { width: 5%; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.info { width: 95%; border-right: 0px; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.anzahl { width: 7.5%; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bezeichnung { width: 30%; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bezeichnung .art-bestand { display: flex; justify-content: space-between; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bezeichnung .art-bestand span { font-size: 0.875em; color: var(--sixth-grey-color); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bezeichnung .art-bestand p { font-size: 0.875em; margin: 0; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bezeichnung .art-bestand p.green { color: var(--primary-color); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bezeichnung .art-bestand p.red { color: var(--red-color); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.einzelpreis { width: 15%; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.einzelpreis span { font-size: 0.875em; color: var(--sixth-grey-color); display: block; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.einzelpreis p { color: var(--eight-grey-color); margin: 0; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.einzelpreis p.guenstiger { color: var(--primary-color);}
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.einzelpreis p.teurer { color: var(--red-color); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.gesamtpreis { width: 15%; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.gesamtpreis span { font-size: 0.875em; color: var(--sixth-grey-color); display: block; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.gesamtpreis p { color: var(--eight-grey-color); margin: 0; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.gesamtpreis p.guenstiger { color: var(--primary-color);}
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.gesamtpreis p.teurer { color: var(--red-color); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen { width: 27.5%; border-right: 0; display: flex; flex-flow: row; flex-wrap: nowrap; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box { display: flex; flex-flow: row; flex-wrap: nowrap; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .qtyminus,
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .qtyplus { min-width: 38px; height: 38px; line-height: 38px; text-align: center; background: var(--first-grey-color); cursor: pointer; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box input[type="text"] { max-width: 100px; border-top:1px solid var(--first-grey-color); border-bottom:1px solid var(--first-grey-color); border-left: none; border-right: none; padding: 4px 8px 3px 8px; max-height: 38px; text-align: center; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart { margin: 0; cursor: pointer; position: relative; outline: none; border: none; -webkit-appearance: none; -webkit-tap-highlight-color: transparent; background: var(--primary-color-rbg); color: var(--white-color); padding: 9px 12px 8px 12px; margin-left: 5px; font-weight: bold; cursor: pointer; transform: scale(var(--s, 1)); box-shadow: 0 var(--s-y, 4px) var(--s-b, 12px) var(--shadow); transition: transform 0.3s, box-shadow 0.3s; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .default { position: relative; opacity: var(--o, 1); transform: scale(var(--s, 1)); transition: transform 0.3s, opacity 0.3s; transition-delay: var(--d, 0.3s); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .default:before, 
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .default:after { content: ""; width: 2px; height: 12px; left: 0; top: 4px; border-radius: 1px; background: var(--icon); position: absolute; transform: rotate(var(--r, 0deg)); transition: transform 0.25s; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .default:after { --r: 90deg; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .success { opacity: var(--o, 0); transform: translate(-50%, var(--y, 12px)); position: absolute; top: 12px; left: 50%; transition: opacity 0.3s, transform 0.3s; transition-delay: var(--d, 0s); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .dots { width: 4px; height: 4px; top: 20px; left: 50%; margin-left: -7px; border-radius: 2px; position: absolute; transform-origin: 10px 50%; background: var(--white-color); box-shadow: 5px 0 0 var(--white-color), 10px 0 0 var(--white-color);  opacity: var(--o, 0); transform: scale(var(--s, 0.4)); transition: opacity 0.3s, transform 0.3s; transition-delay: var(--d, 0s); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart { position: absolute; left: 0; top: 0; right: 0; bottom: 0; z-index: 1; border-radius: inherit; overflow: hidden; -webkit-mask-image: -webkit-radial-gradient(white, black); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart:before { content: ""; position: absolute; width: 20px;  height: 16px; background: var(--background); top: 17px; right: 100%; z-index: 1; margin-right: -20px; transform: translateX(-18px) rotate(-16deg); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart > div { top: 13px; right: 100%; transform: translateX(-18px) rotate(-16deg); position: absolute; z-index: 2; transform-origin: 1px 21px; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart > div:before, 
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart > div:after { content: ""; position: absolute; top: var(--t, 4px); left: var(--l, 0); height: 2px; width: var(--w, 18px); background: var(--white-color); border-radius: 1px; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart > div:after { --w: 16px; --t: 14px; --l: 1px; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart > div > div { width: 2px; height: var(--h, 15px);  border-radius: 1px; transform: rotate(var(--r, -8deg)); background: var(--white-color); position: relative; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart > div > div:before, 
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart > div > div:after { content: ""; position: absolute;  background: inherit; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart > div > div:after { width: 4px; height: 4px; border-radius: 2px; bottom: var(--b, -6px); left: var(--l, 0); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart > div > div:first-child:before { border-radius: inherit; top: 0; right: 0; height: 2px; width: 6px; transform-origin: 5px 1px; transform: rotate(16deg); }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart > div > div:last-child { --h: 12px; --r: 8deg; position: absolute; left: 16px; bottom: -1px; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart .cart > div > div:last-child:after { --l: -2px; --b: -5px; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart.added .default { --o: 0; --s: 1; --d: 0s; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart.added .default:before { --r: -180deg; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart.added .default:after { --r: -90deg; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart.added .dots { --o: 1; --s: 1; --d: .3s; -webkit-animation: dots 2s linear forwards; animation: dots 2s linear forwards; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart.added .success { --o: 1; --y: 0; --d: 1.8s; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart.added .cart:before, 
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart.added .cart > div { -webkit-animation: cart 2s forwards; animation: cart 2s forwards; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart:not(.added):hover { --s: 1; --s-y: 8px; --s-b: 20px; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box .add-to-cart:not(.added):active { --s: .94; --s-y: 2px; --s-b: 6px; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box.disabled .qtyminus,
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box.disabled .qtyplus { cursor: not-allowed; }
.meinebestellungen .bel-container .widget-beleg .datainfo .pos .item.bestellen .box.disabled .add-to-cart { cursor: not-allowed; background: var(--first-grey-color); }

/**********************************************************************************************************
KUNDENKONTO: UMSATZSTATISTIK
**********************************************************************************************************/
.umsatzstatistik .headline h1 { font-size: 2em; color: var(--fifth-grey-color); margin-bottom: 30px; }
.umsatzstatistik .sort-filter-statistic form { display: flex; flex-wrap: nowrap; flex-flow: row; justify-content: space-between; }
.umsatzstatistik .sort-filter-statistic form .full { width: 100%; display: flex; flex-wrap: nowrap; flex-flow: row; }
.umsatzstatistik .sort-filter-statistic form .select-wrapper { width: auto; position: relative; background: var(--first-grey-color); border-radius: 4px; display: inline-block; }
.umsatzstatistik .sort-filter-statistic form .select-wrapper.w80 { width: 80px; }
.umsatzstatistik .sort-filter-statistic form .select-wrapper.w120 { width: 120px; }
.umsatzstatistik .sort-filter-statistic form .select-wrapper.w150 { width: 150px; }
.umsatzstatistik .sort-filter-statistic form .select-wrapper.w170 { width: 170px; }
.umsatzstatistik .sort-filter-statistic form .select-wrapper::before { font-family: "Font Awesome 5 Pro"; font-weight: 900; content: "\f078"; position: absolute; right: 10px; top: 5px; color: var(--fifth-grey-color); }
.umsatzstatistik .sort-filter-statistic form .select-wrapper select { width: 100%; background: transparent; -moz-appearance: none; -webkit-appearance: none; appearance: none; border: none; padding: 4px 10px; }
.umsatzstatistik .sort-filter-statistic form input[type="submit"] { background: var(--first-grey-color); border-radius: 4px; padding: 4px 10px; border: 0; }
.umsatzstatistik .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-top: 15px; margin-bottom: 20px; }
.umsatzstatistik .head p { margin: 0; }

/***********************
LEFT NAVI: INFORMATION
***********************/
.left-information-nav .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 20px; }
.left-information-nav .head p { margin: 0; }
.left-information-nav ul { list-style: none; border: 1px solid var(--first-grey-color); padding: 15px 15px; }
.left-information-nav ul li { border-bottom: 1px solid var(--first-grey-color); }
.left-information-nav ul li:last-child { border-bottom: 0px; }
.left-information-nav ul li a { padding: 5px 0px 5px 0px; color: var(--third-grey-color); font-weight: 400; display: block; }
.left-information-nav ul li.active a { color: var(--fifth-grey-color); font-weight: bold; }

/***********************
KONTAKT
***********************/
.kontakt .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 20px; }
.kontakt .head p { margin: 0; }
.kontakt form .widget { position: relative; z-index: 1; }
.kontakt form .col-lg-12,
.kontakt form .col-lg-6 { margin-bottom: 10px; }
.kontakt form .col-lg-6:nth-child(1) { padding-right: 5px; }
.kontakt form .col-lg-6:nth-child(2) { padding-left: 5px; }
.kontakt form .widget.widget-radio label { padding-left: 12px; margin-bottom: 3px; display: block; }
.kontakt form .widget.widget-radio label:last-child { margin: 0; }
.kontakt form .widget.widget-text input { width: 100%; padding: 20px 12px 5px 12px; border: 1px solid var(--seventh-grey-color); position: relative; z-index: 2; }
.kontakt form .widget.widget-textarea textarea { width: 100%; min-height: 140px; padding: 20px 12px 5px 12px; border: 1px solid var(--seventh-grey-color); position: relative; z-index: 2; }
.kontakt form .widget.widget-textarea textarea:focus { border: 1px solid var(--seventh-grey-color); box-shadow: none; outline: none; }
.kontakt form .widget.widget-text input.active,
.kontakt form .widget.widget-text input:focus { border: 1px solid var(--seventh-grey-color); box-shadow: none; outline: none; }
.kontakt form .widget.widget-select select { width: 100%; background: var(--white-color); padding: 20px 12px 5px 12px; border: 1px solid var(--seventh-grey-color); position: relative; z-index: 2; }
.kontakt form .widget.widget-select select:focus { border: 1px solid var(--seventh-grey-color); box-shadow: none; outline: none; }
.kontakt form .widget label { transition: all .3s ease; }
.kontakt form .widget label.active { transition: all .3s ease; }
.kontakt form .widget.widget-text label { position: absolute; z-index: 3; padding: 0 10px; top: 14px; left: 2px; cursor: text; }
.kontakt form .widget.widget-textarea label { position: absolute; z-index: 3; padding: 0 10px; top: 14px; left: 2px; cursor: text; }
.kontakt form .widget.widget-select label { position: absolute; font-size: 11px; z-index: 3; padding: 0 10px; top: 5px; left: 2px; cursor: text; }
.kontakt form .widget.widget-text label.active { top: 5px; font-size: 11px; }
.kontakt form .widget.widget-textarea label.active { top: 5px; font-size: 11px; }
.kontakt form .widget.widget-select label.active { top: 5px; font-size: 11px; }
.kontakt form .widget.widget-text input:not(:empty):valid + label { top: 5px; font-size: 11px; }
.kontakt form .widget.widget-checkbox { border: 1px solid var(--seventh-grey-color); padding: 12px; }
.kontakt form .widget.widget-checkbox input { position: relative; width: 14px; margin-right: 10px; top: 3px; float: left; cursor: pointer; }
.kontakt form .widget.widget-checkbox label { width: calc(100% - 24px); float: left; cursor: pointer; }
.kontakt form .widget.widget-checkbox label a { color: var(--primary-color); font-weight: bold; }
.kontakt form .widget.widget-submit { text-align: left; }
.kontakt form .widget.widget-submit input[type="submit"] { min-width: 200px; background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); display: inline-block; margin-top: 20px; height: 50px; line-height: 50px; border: none; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; padding: 0px 50px; }
.kontakt form .widget.widget-submit input[type="submit"]:hover { background: var(--primary-color-rbg-hover); }

/*Validation*/
.kontakt form input:user-invalid,
.kontakt form select:user-invalid,
.kontakt form textarea:user-invalid,
.kontakt form .widget-checkbox.invalid { border-color: var(--red-color) !important; } { border-color: var(--red-color) !important; }

/***********************
UNTERNEHMEN
***********************/
.unternehmen .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 20px; }
.unternehmen .head p { margin: 0; }
.unternehmen .text span { font-family: var(--font-family) !important; font-size: 1em !important; color: var(--fifth-grey-color) !important; }

/***********************
AGB
***********************/
.agb .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 20px; }
.agb .head p { margin: 0; }
.agb .text span { font-family: var(--font-family) !important; font-size: 1em !important; color: var(--fifth-grey-color) !important; }

/***********************
IMPRESSUM
***********************/
.impressum .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 20px; }
.impressum .head p { margin: 0; }
.impressum .text span { font-family: var(--font-family) !important; font-size: 1em !important; color: var(--fifth-grey-color) !important; }

/***********************
WIDERRUF
***********************/
.widerruf .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 20px; }
.widerruf .head p { margin: 0; }
.widerruf .text span { font-family: var(--font-family) !important; font-size: 1em !important; color: var(--fifth-grey-color) !important; }

/***********************
ZAHLUNG UND LIEFERUNG
***********************/
.zahlung .head { background: var(--first-grey-color); font-weight: bold; padding: 10px 15px; margin-bottom: 20px; }
.zahlung .head p { margin: 0; }
.zahlung .text span { font-family: var(--font-family) !important; font-size: 1em !important; color: var(--fifth-grey-color) !important; }

/***********************
COOKIE BANNER V3
***********************/
html.show--consent { overflow: hidden; }
html.show--consent #cc--main { width: 100vw; height: 100vh; top: 0; background: rgba(0, 0, 0, .5); }
#cc-main .cm--box.cm--wide { max-width: 470px; }
#cc-main .cm__title { font-size: 1.5em; text-transform: uppercase; color: var(--primary-color); }
#cc-main .cm__desc { color: var(--eight-grey-color); }
#cc-main .cm__btns { border-top: none; }
#cc-main .cm--box.cm--wide .cm__btns { flex-direction: column; padding-top: 0; }
#cc-main .cm--box.cm--wide .cm__btn-group { grid-auto-flow: row; }
#cc-main .cm--box.cm--wide .cm__btn-group:nth-child(1) { order: 2; }
#cc-main .cm--box.cm--wide .cm__btn-group:nth-child(1) button.cm__btn { margin-bottom: 10px; border: none; color: var(--eight-grey-color); }
#cc-main .cm--box.cm--wide .cm__btn-group:nth-child(1) button.cm__btn:first-child { background: var(--primary-color-rbg); font-weight: bold; text-transform: uppercase; }
#cc-main .cm--box.cm--wide .cm__btn-group:nth-child(1) button.cm__btn:first-child:hover { background: var(--primary-color-rbg-hover); }
#cc-main .cm--box.cm--wide .cm__btn-group:nth-child(1) button.cm__btn:last-child { background: var(--first-grey-color); margin-bottom: 0; }
#cc-main .cm--box.cm--wide .cm__btn-group:nth-child(2) { order: 1; }
#cc-main .cm--box.cm--wide .cm__btn-group:nth-child(2) button.cm__btn--secondary { background-color: transparent; padding: 0; border: none; min-height: auto; margin-bottom: 10px; }
#cc-main .cm--box.cm--wide .cm__btn-group:nth-child(2) button.cm__btn--secondary span { background-image: linear-gradient(currentColor,currentColor); background-position: 0 100%; background-repeat: no-repeat; background-size: 0 1px; transition: background-size .25s,color .25s ease; }
#cc-main .cm--box.cm--wide .cm__btn-group:nth-child(2) button.cm__btn--secondary:hover span { background-size: 100% 1px; }
#cc-main .cm--wide .cm__btn + .cm__btn, 
#cc-main .cm--wide .cm__btn-group + .cm__btn-group { margin-left: 0; }
#cc-main .cm__footer { background: var(--white-color); border: none; padding-top: 0; }
#cc-main .cm__link-group { justify-content: center; }
#cc-main .pm__close-btn { background: var(--white-color); }
#cc-main .pm__close-btn:hover { background: var(--white-color); }
#cc-main .pm__close-btn svg { stroke: var(--first-grey-color); }
#cc-main .pm__close-btn:hover svg { stroke: var(--primary-color-rbg); }
#cc-main .pm__section--expandable .pm__section-arrow { background: var(--primary-color-rbg); }
#cc-main .pm__footer { justify-content: flex-start; }
#cc-main .pm__btn + .pm__btn, #cc-main .pm__btn-group + .pm__btn-group { margin: 0; }
#cc-main .pm__btn-group:nth-child(1) button.pm__btn:first-child { background: var(--primary-color-rbg); color: var(--eight-grey-color); border: none; }
#cc-main .pm__btn-group:nth-child(1) button.pm__btn:first-child:hover { background: var(--primary-color-rbg-hover); }
#cc-main .pm__btn-group:nth-child(1) button.pm__btn:last-child { background: var(--first-grey-color); color: var(--eight-grey-color); border: none; }
#cc-main .pm__btn-group:nth-child(2) button.pm__btn { background: var(--primary-color-rbg); color: var(--eight-grey-color); border: none; font-weight: bold; text-transform: uppercase; }
#cc-main .pm__btn-group:nth-child(2) button.pm__btn:hover { background: var(--primary-color-rbg-hover); }
#cc-main .pm__section--toggle .pm__section-title { background: var(--white-color); }
#cc-main .pm__section--toggle .pm__section-title:hover { background: var(--white-color); }

/***********************
ADD TO CART BUTTON
***********************/
.add-to-cart { width: 100%; cursor: pointer; position: relative; outline: none; border: none; -webkit-appearance: none; -webkit-tap-highlight-color: transparent; font-size: 18px; border-radius: 0px; padding: 15px 0px; font-weight: 600; line-height: 20px; color: var(--white-color); background: var(--primary-color-rbg); transition: transform 0.3s, box-shadow 0.3s;  box-shadow: 0 1px 3px 0 var(--fourth-grey-color);  margin-top: 20px; margin-bottom: 10px; }
.add-to-cart:hover { background: var(--primary-color-rbg-hover); }
.add-to-cart .default { padding-left: 0px; position: relative; opacity: var(--o, 1); transform: scale(var(--s, 1)); transition: transform 0.3s, opacity 0.3s; transition-delay: var(--d, 0.3s); }
.add-to-cart .default i { margin-left: 10px; }
.add-to-cart .success { width: 100%; opacity: var(--o, 0); transform: translate(-50%, var(--y, 12px)); position: absolute; top: 15px; left: 50%; transition: opacity 0.3s, transform 0.3s; transition-delay: var(--d, 0s); }
.add-to-cart .success i { margin-left: 10px; }
.add-to-cart .dots { width: 4px; height: 4px; top: 20px; left: 50%; margin-left: -7px; border-radius: 2px; position: absolute; transform-origin: 10px 50%; background: var(--white-color); opacity: var(--o, 0); transform: scale(var(--s, 0.4)); transition: opacity 0.3s, transform 0.3s; transition-delay: var(--d, 0s); }
.add-to-cart.added .default { --o: 0; --s: .8; --d: 0s; }
.add-to-cart.added .default:before { --r: -180deg; }
.add-to-cart.added .default:after { --r: -90deg; }
.add-to-cart.added .dots { --o: 1; --s: 1; --d: .3s; -webkit-animation: dots 2s linear forwards; animation: dots 2s linear forwards; }
.add-to-cart.added .success { --o: 1; --y: 0; --d: 1.8s; }
.add-to-cart:not(.added):hover { --s: 1; --s-y: 8px; --s-b: 20px; }
.add-to-cart:not(.added):active { --s: .94; --s-y: 2px; --s-b: 6px; }

/***********************
ADD TO CART ANIMATION
***********************/
@-webkit-keyframes dots { 5% { transform: translateY(0); } 25% { transform: translateY(-20px) rotate(-32deg); box-shadow: 5px 0 0 var(--white-color), 10px 0 0 var(--white-color); } 31% { box-shadow: 5px -4px 0 var(--white-color), 10px -8px 0 var(--white-color); } 32%, 50% { transform: translateY(0) rotate(0deg); opacity: 1; } 45%, 100% { box-shadow: 5px 0 0 var(--white-color), 10px 0 0 var(--white-color); } 51%, 100% { opacity: 0; } }
@keyframes dots { 5% { transform: translateY(0); } 25% { transform: translateY(-20px) rotate(-32deg); box-shadow: 5px 0 0 var(---white-color), 10px 0 0 var(--white-color); } 31% { box-shadow: 5px -4px 0 var(--white-color), 10px -8px 0 var(--white-color); } 32%, 50% { transform: translateY(0) rotate(0deg); opacity: 1; } 45%, 100% { box-shadow: 5px 0 0 var(--white-color), 10px 0 0 var(--white-color); } 51%, 100% { opacity: 0; } }

/***********************
LADE DIALOG SUCHE
***********************/
#search-loading { background-color: black; position: fixed; top: 0; left: 0; width: 100%; height: 100%; opacity: 0.5; z-index: 9999; }
#search-loading .box { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); color: white; }
#search-loading .box .text {margin-left: -15px;}
#search-loading .box .lds-ring { float: left; position: relative; width: 40px; height: 40px; }
#search-loading .box .lds-ring div { box-sizing: border-box; display: block; position: absolute; width: 32px; height: 32px; margin: 4px; border: 4px solid var(--white-color); border-radius: 50%; animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; border-color: var(--white-color) transparent transparent transparent; }
#search-loading .box .lds-ring div:nth-child(1) { animation-delay: -0.45s; }
#search-loading .box .lds-ring div:nth-child(2) { animation-delay: -0.3s; }
#search-loading .box .lds-ring div:nth-child(3) { animation-delay: -0.15s; }

/***********************
WARTUNGSMODUS
***********************/
body#wartung #topbar { background: var(--first-grey-color); height: 32px; margin: 0; }
body#wartung .container { width: 100%; max-width: 500px; position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); text-align: center; }
body#wartung .container .logo a { background: var(--logo-image); background-size: var(--logo-background-size); width: var(--logo-width); height: var(--logo-height); display: block; margin: 0 auto 30px auto; }
body#wartung .container h1 { font-size: 2em; line-height: 1em; color: var(--fifth-grey-color); text-transform: uppercase; margin: 0 0 30px 0; }

/***********************
FEHLERSEITE
***********************/
body#error #topbar { background: var(--first-grey-color); height: 32px; margin: 0; }
body#error .container { width: 100%; max-width: 500px; position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); text-align: center; }
body#error .container .logo a { background: var(--logo-image); background-size: var(--logo-background-size); width: var(--logo-width); height: var(--logo-height); display: block; margin: 0 auto 30px auto; }
body#error .container #german, body#error .container #englisch { display: none; }
body#error .container h1 { font-size: 2em; font-weight: 400; line-height: 1em; color: var(--fifth-grey-color); text-transform: uppercase; margin: 0 0 30px 0; }
body#error .container button#go-back { display: none; min-width: 200px; background: var(--primary-color-rbg); font-size: 18px; color: var(--white-color); margin-top: 50px; height: 50px; line-height: 50px; border: none; text-align: center; cursor: pointer; box-shadow: 0 1px 3px 0 var(--fourth-grey-color); font-weight: 600; }
body#error .container button#go-back:hover { background: var(--primary-color-rbg-hover); }

/***********************
FOOTER
***********************/
#footer { background: var(--first-grey-color); padding: 30px 0; color: var(--third-grey-color); }
#footer .head p { font-weight: bold; margin-bottom: 20px; padding-bottom: 10px; border-bottom: 1px solid var(--seventh-grey-color); }
#footer ul { list-style: none; padding: 0; margin: 0; }
#footer ul li > img { max-width: 28px; height: auto; margin-right: 10px; margin-bottom: 8px; display: inline-block; }
#footer ul li a { color: var(--third-grey-color); }
#footer ul li button { background: transparent; border: none; color: var(--third-grey-color); }
#footer .row:nth-child(1) .col-lg-4 { border-bottom: 1px solid var(--seventh-grey-color); padding-bottom: 20px; margin-bottom: 30px; }
#footer .row:nth-child(1) .col-lg-4:nth-child(1) a,
#footer .row:nth-child(1) .col-lg-4:nth-child(1) button { background: url(img/icons/001-right-arrow-angle.png) no-repeat; background-size: 16px 16px; padding-left: 25px; display: block; height: 16px; margin-bottom: 10px; }
#footer .row:nth-child(1) .zahlung_versand ul { display: flex; flex-wrap: wrap; }
#footer .row:nth-child(1) .zahlung_versand ul li img { width: auto; height: 28px; max-width: none; }
#footer .support strong { margin-bottom: 20px; display: block; }
#footer .support .telefon { font-size: 16px; font-weight: 600; }
#footer .support .col-lg-6:nth-child(2) { text-align: right; }
#footer .support .col-lg-6:nth-child(2) img { height: 80px; width: auto; }
#footer .support p:last-child { margin: 0; }
#footer .endfooter p { border-top: 1px solid var(--seventh-grey-color); text-align: center; font-size: 0.875em; padding: 30px 0 0 0; margin: 30px 0 0 0; }