 /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    :root {
        --logo-image: url(img/logo.png) no-repeat;
        --logo-background-size: auto 40px;
        --logo-width: 100px;
        --logo-height: 40px;
        --logo-margin: 10px 0;
    }

    /***********************
    GENERELL
    ***********************/
    html, body { overflow-x: hidden; }
    .container { max-width: 96%; }
    #topbar { display: none; }
    #header .row .col-lg-4 { width: 33.3%; }
    #header .logo a { background-size: auto 40px; width: 100px; height: 40px; }
    #header .row .col-lg-8 { width: 66.6%; }
    #header .cart-icon { margin: 20px 10px 0 0; }
    #header .cart-icon a p { display: none; }
    #header .user-icon { margin: 20px 10px 0 0; }
    #header .user-icon a p { display: none; }
    #header .user-icon a::after { display: none; }
    #header .responsive-icon { display: inline-block; }
    #header .row:nth-child(2) { display: none; }
    #searchbar form .search { display: flex; flex-wrap: nowrap; }
    #searchbar form input[type="text"] { width: 100%; }
    #searchbar form input[type="submit"] { background: var(--first-grey-color); border: none; font-family: 'Font Awesome 5 Pro'; padding: 10px 15px; display: block; }
    /***********************
    STARTSEITE
    ***********************/
    #content .startseite .col-lg-2 { display: none; }
    #content .startseite .col-lg-10 .complete_slider { min-height: 0; max-height: none; }
    #content .startseite .col-lg-10 .complete_slider #accordion__img { width: 100%; float: none; }
    #content .startseite .col-lg-10 .complete_slider #accordion__img img { width: 100%; height: auto; min-height: 0; max-height: none; }
    #content .startseite .col-lg-10 .complete_slider #services__accordion { width: 100%; float: none; }
    
    #content .produkte-kategorie h1 { margin-top: 30px; }
    #content .produkte-kategorie .product_filter { flex-wrap: wrap; }
    #content .produkte-kategorie .product_filter .left_filter { width: 100%; display: block; }
    #content .produkte-kategorie .product_filter .left_filter .sort_filter { width: 100%; margin-right: 0; margin-bottom: 10px; }
    #content .produkte-kategorie .product_filter .left_filter .sort_filter .select-wrapper { width: 100%; margin: 0; }
    #content .produkte-kategorie .product_filter .left_filter .sort_filter select { padding: 10px; }
    #content .produkte-kategorie .product_filter .left_filter .sort_filter .select-wrapper::before { top: 10px; }
    #content .produkte-kategorie .product_filter .left_filter .count_filter { width: 100%; }
    #content .produkte-kategorie .product_filter .left_filter .count_filter .select-wrapper { width: 100%; margin: 0; }
    #content .produkte-kategorie .product_filter .left_filter .count_filter select { padding: 10px; }
    #content .produkte-kategorie .product_filter .left_filter .count_filter .select-wrapper::before { top: 10px; }
    #content .produkte-kategorie .product_filter .right_filter { width: 100%; text-align: left; display: block; margin: 10px 0 0 0; }
    #content .produkte-kategorie .product_filter .right_filter .page_info { display: block; }
    #content .produkte-kategorie .product_filter .right_filter .page_info ul { background: var(--first-grey-color); border-radius: 4px; padding: 5px 10px; }
    #content .produkte-kategorie .product_filter .right_filter .page_info ul li a,
    #content .produkte-kategorie .product_filter .right_filter .page_info ul li:hover a { border-bottom: 2px solid var(--first-grey-color); }
    #content .produkte-kategorie .product_filter .right_filter .ansicht { margin: 0; display: none; }
    #content .produkte-kategorie .product_list .product_list_grid .product { width: calc(50% - 12px); }
    #content .produkte-kategorie .product_list .product_list_grid .product:nth-child(2n) { margin-right: 0; }
    
    /***********************
    PRODUKTDETAILS
    ***********************/
    .produktdetails h1 { width: 100%; }
    .produktdetails .col-lg-6 .head { margin-top: 30px; }
    
    /***********************
    REGISTRIERUNG
    ***********************/
    .registrierung-formular form .col-lg-6:nth-child(1) { padding-right: calc(var(--bs-gutter-x) * .5); }
    .registrierung-formular form .col-lg-6:nth-child(2) { padding-left: calc(var(--bs-gutter-x) * .5); }
    
    /***********************
    LOGIN
    ***********************/
    .loginpage .col-lg-2 { order: 3; }
    .loginpage .col-lg-2 .head { margin-top: 30px; }
    .loginpage .col-lg-5.login { order: 1; }
    .loginpage .col-lg-5.reg { order: 2; }
    .loginpage .col-lg-5.reg .head { margin-top: 30px; }
    .loginpage ul.vorteile { margin: 0; }
    
    /***********************
    MEIN PROFIL
    ***********************/
    .kundenkonto .row .data.firmendaten { margin-top: 20px; }
    
    /***********************
    MEINE BESTELLUNGEN
    ***********************/
    .meinebestellungen .sort-filter-orders { display: block; }
    .meinebestellungen .sort-filter-orders .left label, 
    .meinebestellungen .sort-filter-orders .right label { width: 100%; margin-right: 0; margin-bottom: 5px; }
    .meinebestellungen .sort-filter-orders .left .widget { width: 100%; margin: 0; }
    .meinebestellungen .sort-filter-orders .select-wrapper.w170 { width: 100%; margin-bottom: 5px; }
    .meinebestellungen .sort-filter-orders .select-wrapper::before { top: 10px; }
    .meinebestellungen .sort-filter-orders .select-wrapper select { padding: 10px; }
    .meinebestellungen .sort-filter-orders .right { display: block; }
    .meinebestellungen .sort-filter-orders .right .widget { width: 100%; margin: 0; }
    .meinebestellungen .sort-filter-orders .select-input { margin-bottom: 5px; display: block; }
    .meinebestellungen .sort-filter-orders .right .widget-vonbelzeitraum input, 
    .meinebestellungen .sort-filter-orders .right .widget-bisbelzeitraum input { width: 100%; padding: 10px; }
    .meinebestellungen .sort-filter-orders button { margin-top: 10px; margin-bottom: 30px; padding: 10px; }
    .meinebestellungen .head { margin-top: 0; }
    .meinebestellungen .bel-container .widget-beleg .vorgangsnr p { margin-bottom: 5px; }
    .meinebestellungen .bel-container .widget-beleg .belstatus p { text-align: left; }
    .meinebestellungen .bel-container .widget-beleg .info p span { width: 120px; }
    
    /***********************
    WARENKORB
    ***********************/
    .warenkorb-site .col-lg-3 .cart_box { margin-top: 30px; }
    .warenkorb-site .cart_box .product_pos .prod_summe .summe_price { line-height: 38px; margin: 0; }
    .warenkorb-site .cart_box .product_pos .prod_summe > a { margin-top: 20px; display: block; position: relative; }
    .warenkorb-site .cart_box .product_pos .prod_info p.bestand { margin-bottom: 20px; }
    
    /***********************
    CHECKOUT
    ***********************/
    .checkout-steps ul { display: block; }
    .checkout-steps ul li { width: 100%; margin-bottom: 10px; }
    .checkout-steps ul li.check,
    .checkout-steps ul li.active { background: var(--white-color); }
    .checkout-steps ul li:last-child { margin: 0; }
    .rechnungsadresse .col-lg-6:nth-child(1) { padding-right: calc(var(--bs-gutter-x) * .5); }
    .rechnungsadresse .col-lg-6:nth-child(2) { padding-left: calc(var(--bs-gutter-x) * .5); }
    
    /***********************
    KONTAKT
    ***********************/
    .kontakt form .col-lg-6:nth-child(1) { padding-right: calc(var(--bs-gutter-x) * .5); }
    .kontakt form .col-lg-6:nth-child(2) { padding-left: calc(var(--bs-gutter-x) * .5); }
    
    /***********************
    FOOTER
    ***********************/
    #footer .row:nth-child(1) .col-lg-4 { border-bottom: none; padding-bottom: 0; }
    #footer .support .col-lg-6:nth-child(2) { display: none; }

    /***********************
    RESPONSIVE NAV
    ***********************/
    .offcanvas.show { visibility: visible; overflow-y: scroll; }
    .offcanvas-start { width: 100%; border-right: 0px; }
    .offcanvas .resp-header .row .col-lg-4 { width: 33.3%; }
    .offcanvas .resp-header .row .col-lg-4 .logo a { background: var(--logo-image); background-size: var(--logo-background-size); width: var(--logo-width); height: var(--logo-height); display: block; margin: var(--logo-margin); }
    .offcanvas .resp-header .row .col-lg-8 { width: 66.6%; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon { display: inline-block; margin: 20px 10px 0 0; position: relative; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon a { height: 40px; font-size: 0.85em; color: var(--third-grey-color); display: block; font-weight: 600; text-align: center; transition: all ease 0.3s; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon a:hover { color: var(--primary-color); transition: all ease 0.3s; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon a p { margin: 0; display: none; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon a i { font-size: 24px; margin: 0 0 5px 0; display: block; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon a::after { display: none; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon ul { min-width: 220px; border-radius: 0; padding: 0; border: 0; -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); transform: translate(0px, 45px) !important; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon ul li.head { background: var(--primary-color); text-align: center; font-size: 0.9em; font-weight: bold; color: var(--white-color); padding: 8px 5px; margin-bottom: 5px; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon ul li a { font-size: 0.9em; font-weight: 400; background: transparent; height: auto; padding: 1px 0; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon ul li a:hover { background: transparent; height: auto; padding: 1px 0; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon ul li.logout a { width: calc(100% - 20px); margin: 10px 10px 10px 10px; padding: 3px 0; background: var(--primary-color-rbg); display: block; color: var(--fifth-grey-color); font-weight: bold; }
    .offcanvas .resp-header .row .col-lg-8 .user-icon ul li.logout a:hover { background: var(--primary-color-rbg-hover); }
    .offcanvas .resp-header .row .col-lg-8 .cart-icon { display: inline-block; margin: 20px 10px 0 0; }
    .offcanvas .resp-header .row .col-lg-8 .cart-icon a { height: 40px; font-size: 0.85em; color: var(--third-grey-color); display: block; font-weight: 600; text-align: center; position: relative; transition: all ease 0.3s; }
    .offcanvas .resp-header .row .col-lg-8 .cart-icon a:hover { color: var(--primary-color); transition: all ease 0.3s; }
    .offcanvas .resp-header .row .col-lg-8 .cart-icon a p { margin: 0; display: none; }
    .offcanvas .resp-header .row .col-lg-8 .cart-icon a i { font-size: 24px; margin: 0 0 5px 0; display: block; }
    .offcanvas .resp-header .row .col-lg-8 .cart-icon a span#resp-count-wk { position: absolute; color: var(--third-grey-color); font-weight: 400; background: var(--primary-color); top: -5px; right: 10px; width: 18px; height: 18px; line-height: 18px; border-radius: 50%; }
    .offcanvas .resp-header .row .col-lg-8 .cart-icon a:hover span#resp-count-wk { color: var(--black-color); }
    .offcanvas .resp-header .row .col-lg-8 .responsive-icon { display: inline-block; }
    .offcanvas .resp-header .row .col-lg-8 .responsive-icon button { width: 23px; height: auto; background: transparent; font-size: 24px; color: var(--third-grey-color); margin: 0; display: inline-block; vertical-align: inherit; padding: 0 !important; transition: all ease 0.3s; opacity: 1; }
    .offcanvas .resp-header .row .col-lg-8 .responsive-icon button:hover { color: var(--primary-color); transition: all ease 0.3s; }
    .offcanvas .resp-header .row .col-lg-8 .responsive-icon button:focus { box-shadow: none; }
    .offcanvas .resp-header .row .col-lg-8 .responsive-icon button::after { font-family: 'Font Awesome 5 Pro'; content: "\f00d"; font-weight: 500; }
    .offcanvas .resp-search { background: var(--primary-color); }
    .offcanvas .resp-search form { margin: 10px 0; }
    .offcanvas .resp-search form .search { display: flex; flex-wrap: nowrap; }
    .offcanvas .resp-search form input[type="text"] { width: 100%; padding: 10px 8px; border: 0; }
    .offcanvas .resp-search form input[type="submit"] { background: var(--first-grey-color); border: none; font-family: 'Font Awesome 5 Pro'; padding: 10px 15px; display: block; }
    .offcanvas .resp-body { flex-grow: 1; background: var(--white-color); border-top: 1px solid var(--second-grey-color); }
    .offcanvas .resp-body .resp-nav ul { list-style: none; padding: 0; margin: 0; }
    .offcanvas .resp-body .resp-nav ul li { position: relative; border-bottom: none; }
    .offcanvas .resp-body .resp-nav ul li a { color: var(--eight-grey-color); display: block; padding: 10px 4%; margin: 0 auto; border-bottom: 1px solid var(--second-grey-color); }
    .offcanvas .resp-body .resp-nav ul li a:hover { }
    .offcanvas .resp-body .resp-nav ul li i { text-decoration: none; font-style: normal; cursor: pointer; }
    .offcanvas .resp-body .resp-nav ul li i::after { width: 50px; content: '\f067'; font-family: 'Font Awesome 5 Pro'; position: absolute; top: 0; right: 3%; display: block; font-size: 16px; line-height: 42px; font-weight: 900; padding: 0px 10px 0px 25px; color: var(--third-grey-color); border-left: 1px solid var(--second-grey-color); }
    .offcanvas .resp-body .resp-nav ul li i.active::after { content: '\f068'; }
    .offcanvas .resp-body .resp-nav ul li ul { background: var(--second-grey-color); display: none; }
    .offcanvas .resp-body .resp-nav ul li ul.active { display: block; }
    .offcanvas .resp-body .resp-nav ul li ul li a { background: url(img/icons/001-right-arrow-angle.png) no-repeat transparent; background-size: 12px 12px; background-position: 17px 15px; padding: 10px 4% 10px 7.5%; border-bottom: 1px solid var(--white-color); }
    .offcanvas .resp-body .resp-nav ul li ul li ul { display: none; }
    .offcanvas .resp-body .resp-language ul { list-style: none; padding: 0; margin: 0; }
    .offcanvas .resp-body .resp-language ul li { position: relative; border-bottom: none; }
    .offcanvas .resp-body .resp-language ul li a { color: var(--eight-grey-color); display: block; padding: 10px 4%; margin: 0 auto; border-bottom: 1px solid var(--second-grey-color); }
    .offcanvas .resp-body .resp-language ul li i { text-decoration: none; font-style: normal; cursor: pointer; }
    .offcanvas .resp-body .resp-language ul li i::after { width: 50px; content: '\f067'; font-family: 'Font Awesome 5 Pro'; position: absolute; top: 0; right: 3%; display: block; font-size: 16px; line-height: 42px; font-weight: 900; padding: 0px 10px 0px 25px; color: var(--third-grey-color); border-left: 1px solid var(--second-grey-color); }
    .offcanvas .resp-body .resp-language ul li i.active::after { content: '\f068'; }
    .offcanvas .resp-body .resp-language ul li ul { background: var(--second-grey-color); display: none; }
    .offcanvas .resp-body .resp-language ul li ul.active { display: block; }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
} 